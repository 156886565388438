import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { confirmAlert } from 'react-confirm-alert'; 
import { v4 as uuidv4 } from 'uuid';

import CurveComponent from "../../log-viewer/curve-component";
import XScaleComponent from "../../log-viewer/xscale-component";
import CurveOrder from "./curve-order";
import AddCurve from "./add-curve";
import { getRandomColor } from "../../random";
import LithologyEdit from "../../log-viewer/lithology/lithology-edit";
import LithologyAdd from "../../log-viewer/lithology/lithology-add";
import './graph-adjustment-tab.scss';
import TextAdd from "../../log-viewer/text-track/text-add";
import TextEdit from "../../log-viewer/text-track/text-edit";

const GraphAdjustmentsTab = ({ track, 
                               trackTypeCategories, 
                               setSelectedTrack, 
                               setCollapsed, 
                               isSelected, 
                               updateCurves, 
                               setShowGridlines,                                
                               setScaleType,
                               deleteTrack,                               
                               clientId,
                               changeDataValue,
                               saveLithologyData,
                               saveTextData,
                               changeDepth,
                               changeTextData,
                               addDataValue,
                               addTextValue,                               
                               deleteDataValue,
                               selectedLithologyId,
                               setSelectedLithologyId,
                               showAdjustmentsPanel,
                               setTrackEditMode,
                               dataNotSaved
                                } : any) => {

const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {      
    // if (track?.scrollAdjustmentIntoView && fieldRef.current) {
    //   fieldRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "end"
    //   });
    // }
  }, [track?.scrollAdjustmentIntoView]);

  const curves = track?.curves;

  const onDelete = () => {
    confirmAlert({
      title: 'Confirm Delete track',
      message: `Are you sure you want to remove track ${track.displayName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteTrack(track.id);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  let maxCurves = 0;
  if (track.trackType === 0 || track.trackType === 2) { // graph + multi curve
    maxCurves = 4;  
  } else if (track.trackType === 3) { // Cumulative
    maxCurves = 15;
  } else if (track.trackType === 4) { // Tadpole
    maxCurves = 2;
  }

  const tabs = [
    { number: 0, display: track.trackType != 1 && track.trackType != 5 && track.trackType != 7, icon: <img src={"/images/icons/Atlas X Axis Range Icon.svg"} className="tab-icon" title="Set X axis range"/> },
    { number: 1, display: track.trackType != 1 && track.trackType != 5 && track.trackType != 7, icon: <img src={"/images/icons/Atlas Curve Attributes Icon.svg"} className="tab-icon" title="Set curve attributes"/> },
    { number: 2, display: track.trackType != 1 && track?.curves?.length > 1, icon: <img src={"/images/icons/Atlas Reorder Icon black.svg"} className="tab-icon" title="Reorder"/> },
    { number: 3, display: track.trackType != 1 && track?.curves?.length < maxCurves, icon: <img src={"/images/icons/Atlas Add Track Icon black.svg"} className="tab-icon" title="Add"/> },
    { number: 4, display: true, icon: <img src={"/images/icons/Atlas Delete Icon.svg"} className="tab-icon" title="Delete"/>, click: onDelete },
  ];

  if (track.trackType === 5 || track.trackType === 7) { // Lithology || Text
    tabs.unshift({ number: 5, display: track.trackType == track.trackType, icon: <img src={"/images/icons/Atlas Add Track Icon black.svg"} className="tab-icon" title="Add"/> });
    tabs.unshift({ number: 6, display: track.trackType == track.trackType, icon: <img src={"/images/icons/Pencil Icon.svg"} className="tab-icon" title="Edit"/> });
  }

  const firstVisibleTrack = tabs.find((tab: any) => tab.display);
  const [selectedTab, setSelectedTab] = useState<number>(firstVisibleTrack?.number ?? 0);

    useEffect(() => {
        if (isSelected) {
          setCollapsed(track.id, false);
        }
      }, [isSelected]);

    const onSetSelectedTrack = () => {    
    setSelectedTrack(track);
    };  
    
    const onSetCollapsed = () => {
      if (track.collapsed) {
          onSetSelectedTrack();
          setCollapsed(track.id, false);
      }
      if (!track.collapsed) {      
        setSelectedTrack(null);
        setCollapsed(track.id, true);
      }
    };

    const onUpdateCurves = (curves: any) => {
      updateCurves(track.id, curves)
    };

    const onSetShowGridlines = (show: boolean) => {
      setShowGridlines(track.id, show);
    };

    const onSetScaleType = (type: any) => {
      setScaleType(track.id, type);
    };

    const onAddCurve = (trackTypeId: number, trackTypeName: string) => {
      var curvesCopy = curves.slice();
      const color = getRandomColor();
      const id = uuidv4();
      
      const allTrackTypes = trackTypeCategories.reduce((previousValue : any, currentValue: any) => {
        if (currentValue.trackTypes) {
          previousValue.push(...currentValue.trackTypes);
        }
        return previousValue;
      }, []);

      const trackType = allTrackTypes.find((trackType: any) => trackType.id === trackTypeId);
      if (!trackType) {
        return;
      }

      curvesCopy.push({ 
        id: id,
        displayName: trackTypeName,
        trackTypeId: trackTypeId,
        new: true,
        displayOrder: curvesCopy.length + 1,
        fillColor: color + "80",
        fillSize: 5, 
        fillStyle: "",
        lineColor: color,
        lineSize: 1,
        lineStyle: "line",
        majorIntervals: 6,
        manualMajorIntervals: false,
        manualMinorIntervals: false,
        manualScaleXMaximum: false,
        manualScaleXMinimum: false,
        minorIntervals: 1,
        pointColor: color,
        pointSize: 5,
        pointStyle: "circle",
        scaleType: 0,
        showFill: true,
        showLine: true,
        showPoints: false,
        units: "",
        xScaleMaximum: null,
        xScaleMinimum: null,
        allowedCurveTypes: trackType.allowedCurveTypes,
        curveType: trackType.allowedCurveTypes[0] });
      updateCurves(track.id, curvesCopy);
      setSelectedTab(1);
    };

    const onChangeDataValue = (id: string, value: any, color: string, lithologyLexiconId: string) => {
      changeDataValue(id, track.lithologyTypeId, value, color, lithologyLexiconId);
    };

    const onChangeTextData = (data: any) => {      
      changeTextData(track.textTrackTypeId, data);
    };

    const onDeleteData = (id: any) => {
      deleteDataValue(track.lithologyTypeId, id);
    };
    
    const onSaveData = () => {      
      saveLithologyData(track.lithologyTypeId);
    };

    const onSaveTextData = (data: any) => {            
      saveTextData(track.textTrackTypeId, data);
    };

    const onChangeDepth = (id: any, newDepth: any) => {
      changeDepth(track.lithologyTypeId, id, newDepth);
    };

    const onDeleteTextData = (id: any) => {
      deleteDataValue(track.textTrackTypeId, id);
    };

    const onAddDataValue = (depth: any, value: any, color: string, lithologyLexiconId: string) => {
      addDataValue(track.lithologyTypeId, depth, value, color, lithologyLexiconId);
      setSelectedTab(6);
    };

    const onSetTrackEditMode = (mode: boolean) => {
      setTrackEditMode(track.id, mode)
    };

    const onAddTextValue = (fromDepth: number, toDepth: number, value: any) => {
      const valid = addTextValue(track.textTrackTypeId, fromDepth, toDepth, value);
      if (valid) {
        setSelectedTab(6);
      }
      return valid;
    };
    
    return (
      <div ref={fieldRef} style={{height: "100%", display: "block"}}>
        <div className={`collapsible is-size-6 ${isSelected ? "track-active" : ""}`} onClick={onSetCollapsed}>
          <div className="track-order-label">{track.label} </div>
          {dataNotSaved && <div className="track-data-not-saved"></div>}
          <div className="collapsible-icon">
            <Icon icon="mdi:chevron-down-circle-outline" width="24" height="24" rotate={track.collapsed ? 0 : 2} />
          </div>
        </div>
        <div onMouseDown={onSetSelectedTrack} className="collapsible-content" style={{display: track.collapsed ? "none" : "inline-block", overflow: "visible" }}>
            <div className="tabs is-boxed panel-tab">
                <ul>
                    {tabs.map((tab: any, index: number) => (
                      <>
                        {tab.display && <li key={index} className={selectedTab == tab.number ? 'is-active' : ''} onClick={() => tab.click ? tab.click() : setSelectedTab(tab.number)}><a>{tab.icon}</a></li> }
                     </>
                    ))}                    
                </ul>          
            </div>
            <div className="adjustment-tab-tab">
              { selectedTab === 0 && 
                <XScaleComponent dialogCurves={track?.curves} 
                  setDialogCurves={onUpdateCurves}
                  dialogShowGridlines={track?.showGridlines}
                  dialogScaleType={track?.scaleType}
                  setDialogShowGridlines={onSetShowGridlines}
                  setDialogScaleType={onSetScaleType}                  
                />
              }
              {selectedTab === 1 &&                 
                <CurveComponent dialogCurves={track?.curves} 
                    setDialogCurves={onUpdateCurves}
                    trackTypes={trackTypeCategories}
                    showPointProperties={true}
                    showLineProperties={true}
                    showFillProperties={true}            
                    canAdd={true}    
                    showStyles={true} />                
              }
              { selectedTab === 2 && 
                <CurveOrder curves={track?.curves} setDialogCurves={onUpdateCurves} />
                //?.sort((a: any, b:any) => a.displayOrder - b.displayOrder)
              }
              {selectedTab === 3 &&
                <AddCurve trackTypeCategories={trackTypeCategories} addCurve={onAddCurve} />
              }
              {selectedTab === 5 && track.trackType === 5 &&
                <LithologyAdd clientId={clientId}
                  lithologyData={track?.datas}
                  canAdd={true}
                  saveData={onSaveData}
                  addDataValue={onAddDataValue}
                   /> 
              }
               {selectedTab === 6 && track.trackType === 5 &&
                  <LithologyEdit   
                      clientId={clientId}
                      lithologyData={track?.datas}
                      canAdd={true}
                      changeDataValue={onChangeDataValue}
                      saveData={onSaveData}
                      changeDepth={onChangeDepth}
                      deleteData={onDeleteData}
                      selectedLithologyId={selectedLithologyId}
                      setSelectedLithologyId={setSelectedLithologyId}
                      visible={showAdjustmentsPanel}
                      setTrackEditMode={onSetTrackEditMode}
                      trackEditMode={track.trackEditMode} />
                }   
              {selectedTab === 5 && track.trackType === 7 &&
                <TextAdd clientId={clientId}                  
                  canAdd={true}
                  saveData={onSaveData}
                  addTextValue={onAddTextValue}
                   /> 
              }
               {selectedTab === 6 && track.trackType === 7 &&
                  <TextEdit   
                      clientId={clientId}    
                      textData={track?.datas}                  
                      canAdd={true}
                      changeData={onChangeTextData}
                      saveData={onSaveTextData}                      
                      deleteData={onDeleteTextData}                      
                      visible={showAdjustmentsPanel}
                      setTrackEditMode={onSetTrackEditMode}
                      trackEditMode={track.trackEditMode} />
                }              
            </div>
        </div>
    </div>
    )
};

export default GraphAdjustmentsTab;