import React from 'react';
import './track-order-panel.scss';
import { Draggable } from 'react-beautiful-dnd';

const TrackOrder = (props: any) => {
    const {
      track,
      index
    } = props;  

return (
    <Draggable draggableId={track.id} index={index}>
        {(provided: any) => (
            <div className="track-order" 
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                <div className="track-order-label">
                  {track.label}
                </div>
                <div className="track-order-icon">              
                    <i className="material-icons">reorder</i>
                </div>
            </div>
        )}
    </Draggable>
)};

export default TrackOrder;