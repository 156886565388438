import React, { useEffect, useState } from "react";
import Select from 'react-select';
const AddCurve = ({ trackTypeCategories, addCurve }: any) => {

  const [selectedTrackCategory, setSelectedTrackCategory] = useState<any>(null);
  const [trackTypeId, setTrackTypeId] = useState<number>(17); //trackTypes[0]?.trackTypes[0]?.id

  useEffect(() => {      
    //setTrackTypeId(trackTypes[0]?.trackTypes[0]?.id);
    setSelectedTrackCategory(trackTypeCategories[0]);
   }, [])

  useEffect(() => {      
    if (selectedTrackCategory?.id) {
        setTrackTypeId(+selectedTrackCategory.trackTypes[0].id);
    }
   }, [selectedTrackCategory?.id])
  
  const onChangeTrackCategoryId = (e: any) => {    
    const trackTypeCategory = trackTypeCategories.find((trackCategory: any) => trackCategory.id == e.target.value);
    setSelectedTrackCategory(trackTypeCategory);
  }; 

  const onChangeTrackTypeId = (e: any) => {
    const value = e.target.value;
    setTrackTypeId(+value);    
  };

  const onAddCurve = () => {      
    debugger;
    const trackType = selectedTrackCategory.trackTypes.find((trackType: any) => trackType.id === trackTypeId);
    addCurve(trackTypeId, trackType?.name);
  };

    return (
        <>
          <div className="field">
            <label className="label">
              Curve Category
            </label>
            <div className="field-body">
              <div className="select">          
                <select value={selectedTrackCategory?.id} onChange={onChangeTrackCategoryId}>
                  {(trackTypeCategories || []).map((trackCategory: any, index: number) => 
                    <option key={index} value={trackCategory.id}>{trackCategory.name}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="label">
               Curve Type
            </div>
            <div className="field-body">
              <div className="select">          
                <select value={trackTypeId} onChange={onChangeTrackTypeId}>
                  {(selectedTrackCategory?.trackTypes || []).map((trackType: any, index: number) => 
                    <option key={index} value={trackType.id}>{trackType.name}</option>
                  )}
                </select>
              </div>
            </div>
        </div>

        <button className="button is-light" onClick={onAddCurve}>Add</button>
      </>
    )
};

export default AddCurve;