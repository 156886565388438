import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams  } from 'react-router-dom';
import { toast } from 'react-toastify';
import Errors from '../../components/errors';
import Loader from '../../components/loader';
import { useAddMutation } from "../../services/boreholeApi";
import { useGetClientLogoQuery } from "../../services/boreholeApi";
import { useBoreholeStatusesQuery } from '../../services/enumApi';
import { useEquipmentQuery } from '../../services/lookupApi';
import { useProjectsQuery } from '../../services/projectApi';
import getConfig from "../../app/config";
import auth from '../../components/auth';
import BoreholeForm from './BoreholeForm';
import { useAppDispatch } from '../../app/hooks';
import { setClearCache } from "../../services/boreholesSlice";

const AddBorehole = ({setBorehole} : any) => {
  let { clientId, projectId } = useParams<any>();
  
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [loggingStarted, setLoggingStarted] = useState();
  const [loggingEnded, setLoggingEnded] = useState();
  const [loggingTeam, setLoggingTeam] = useState();
  const [comments, setComments] = useState();
  const [checkedEquipment, setCheckedEquipment] = useState<Array<string>>([]);
  const [status, setStatus] = useState();
  const [file, setFile] = useState<any>();
  const [clientLogoFileName, setClientLogoFileName] = useState<string>();
  const [selectedProjectId, setSelectedProjectId] = useState<string>(projectId);
  const [curated, setCurated] = useState<boolean>(true);
  const [useSqlServer, setUseSqlServer] = useState<boolean>(true);

  const [totalLoggedMeterage, setTotalLoggedMeterage] = useState();
  const [numberofDigitizedBoxes, setNumberofDigitizedBoxes] = useState();
  const [uploadDate, setUploadDate] = useState();
  const [resolutionBoxScan, setResolutionBoxScan] = useState();
  const [resolutionHsi, setResolutionHsi] = useState();
  const [notes, setNotes] = useState();

  useEffect(() => {
    setBorehole(null);
  },[]);

  // useEffect(() => {
  //   if (projectId) {
  //     setSelectedProjectId(projectId);
  //   }
  // },[projectId]);

  const { data: projects, isLoading: projectsIsLoading, error: projectError } = useProjectsQuery(clientId);
  const { data, error, isLoading: isLoadingLogo, isFetching } = useGetClientLogoQuery( { clientId });

  useEffect(() => {
    if (projects?.projects && projectId === "undefined") {
      if (projects.projects.length > 0) {        
        setSelectedProjectId(projects.projects[0].id);
      }      
    }
  },[projectsIsLoading]);

  useEffect(() => {
    if (!isLoadingLogo) {     
      setClientLogoFileName(`${apiUrl}client/${clientId}/download/ClientLogo/${data?.clientLogoFileName}?access_token=${auth.getToken()}`);
    }
  },
  [isLoadingLogo])

  const onSetName = (name: string) => {
    setName(name);
    setBorehole({name});
  };

  let history = useHistory();

  const { data: boreholeStatuses, isLoading: boreholeStatusesIsLoading } = useBoreholeStatusesQuery();
  const { data: equipments, isLoading: equipmentsIsLoading } = useEquipmentQuery();

    const [add, { isLoading, error: addError }] = useAddMutation();

    const apiUrl = getConfig().apiUrl;

    const dispatch = useAppDispatch();

    const onAddBorehole = (e: any) => {
      e.preventDefault();
      add({ clientId, 
            projectId: selectedProjectId, 
            name, 
            latitude, 
            longitude, 
            loggingStarted, 
            loggingEnded, 
            loggingTeam, 
            comments, 
            status, 
            equipment: checkedEquipment, 
            curated, 
            useSqlServer,
            totalLoggedMeterage,           
            numberofDigitizedBoxes, 
            uploadDate, 
            resolutionBoxScan, 
            resolutionHsi, 
            notes
       })
      .unwrap()
      .then((response: any) => {
        dispatch(setClearCache(true));
        if (response.success) {
          history.push(`/client/${clientId}/project/${selectedProjectId}/borehole/${response.boreholeId}/files`);  // redirect
          toast.success("Successfully added borehole");
        }
       })      
       .catch((error) => { debugger; toast.error("Error adding borehole") });;      
    };
 
    return (
      <section className="section">
        <div>
            <h1>Add Borehole</h1>
            <Errors error={addError}/>
            {projects?.projects?.length === 0 && <div>No projects, please add one first 
              <div><Link className='button mt-4' to={`/client/${clientId}/project/add`}>Add Project</Link></div>
              </div>}
            {(isLoading || boreholeStatusesIsLoading || equipmentsIsLoading || isLoadingLogo || projectsIsLoading) && <Loader/>}
            {projects?.projects?.length > 0 && <BoreholeForm             
              boreholeStatuses={boreholeStatuses}
              equipments={equipments?.equipments}
              name={name} 
              setName={onSetName} 
              latitude={latitude} 
              setLatitude={setLatitude} 
              longitude={longitude} 
              setLongitude={setLongitude} 
              loggingStarted={loggingStarted}
              setLoggingStarted={setLoggingStarted}
              loggingEnded={loggingEnded}
              setLoggingEnded={setLoggingEnded}
              loggingTeam={loggingTeam}
              setLoggingTeam={setLoggingTeam}              
              comments={comments}
              setComments={setComments}
              status={status}
              setStatus={setStatus}
              checkedEquipment={checkedEquipment}
              setCheckedEquipment={setCheckedEquipment}
              curated={curated}
              setCurated={setCurated}
              useSqlServer={useSqlServer}
              setUseSqlServer={setUseSqlServer}
              canChangeUseSqlServer={true}
              onSaveBorehole={onAddBorehole}
              file={file}
              setFile={setFile}
              clientLogoFileName={clientLogoFileName}
              projects={projects?.projects}
              selectedProjectId={selectedProjectId}
              setSelectedProjectId={setSelectedProjectId}
              canChangeProject={true}
              totalLoggedMeterage={totalLoggedMeterage}
                setTotalLoggedMeterage={setTotalLoggedMeterage}
                numberofDigitizedBoxes={numberofDigitizedBoxes}
                setNumberofDigitizedBoxes={setNumberofDigitizedBoxes}
                uploadDate={uploadDate}
                setUploadDate={setUploadDate}
                resolutionBoxScan={resolutionBoxScan}
                setResolutionBoxScan={setResolutionBoxScan}
                resolutionHsi={resolutionHsi}
                setResolutionHsi={setResolutionHsi}
                notes={notes}
                setNotes={setNotes}               />}
        </div>
      </section>
    )
};

export default AddBorehole;