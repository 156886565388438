import React, { useEffect, useState } from "react";
import '../image-filter.scss';
import '../panel.scss';

import GraphAdjustmentsTab from "./graph-adjustments-tabs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RightPanel from "../right-panel";

const GraphAdjustments = ({ tracks, 
                            trackTypeCategories, 
                            selectedTrack, 
                            setSelectedTrack, 
                            setTrackCollapsed, 
                            updateCurves, 
                            setShowGridlines, 
                            setScaleType, 
                            deleteTrack,
                            addCurve,
                            lithologyData,
                            clientId,
                            changeDataValue,
                            saveLithologyData,
                            saveTextData,
                            changeDepth,
                            changeTextData,                            
                            addDataValue,
                            addTextValue,                            
                            deleteDataValue,
                            selectedLithologyId,
                            showAdjustmentsPanel,
                            setSelectedLithologyId,
                            setTrackEditMode,
                            dataNotSaved } : any) => {  
  
  const adjustableTracks = tracks?.filter((track: any) => !track.noData || track.trackType === 5);
  const tracksOptions = adjustableTracks?.map((track: any) => { return { label: track.label, value: track.id } });

  const selectedTrackOption = tracksOptions?.find((track: any) => track.value === selectedTrack?.id);
  
  const onSetTrackCollapsed = (trackId: string, collapsed: boolean) => {
    setTrackCollapsed(trackId, collapsed);
  };
    
  return (
    <RightPanel title="Adjustments" icon="Atlas Adjustment Icon">    
      <DndProvider backend={HTML5Backend}>
        <div>
              {(adjustableTracks || []).map((track: any, index: number) => {    
                                             
                const trackDataNotSaved = dataNotSaved.findIndex((t: any) => t ==  track.lithologyTypeId) >= 0;
                return(
                <GraphAdjustmentsTab key={track.id} 
                  track={track} 
                  tracks={tracks} 
                  trackTypeCategories={trackTypeCategories}
                  setSelectedTrack={setSelectedTrack}
                  setCollapsed={onSetTrackCollapsed}
                  isSelected={track.id === selectedTrack?.id}
                  updateCurves={updateCurves}
                  setShowGridlines={setShowGridlines} 
                  setScaleType={setScaleType}
                  deleteTrack={deleteTrack}
                  addCurve={addCurve}     
                  lithologyData={lithologyData}
                  clientId={clientId}
                  changeDataValue={changeDataValue}
                  changeTextData={changeTextData}
                  saveLithologyData={saveLithologyData}
                  saveTextData={saveTextData}
                  changeDepth={changeDepth}                  
                  addDataValue={addDataValue}
                  addTextValue={addTextValue}
                  deleteDataValue={deleteDataValue}
                  selectedLithologyId={selectedLithologyId}
                  setSelectedLithologyId={setSelectedLithologyId}
                  showAdjustmentsPanel={showAdjustmentsPanel}
                  setTrackEditMode={setTrackEditMode} 
                  dataNotSaved={trackDataNotSaved}                 
                />              
              )})}            
        </div>
       </DndProvider>
    </RightPanel>
  );
}

export default GraphAdjustments;
