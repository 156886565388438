import React, { useEffect, useState } from 'react';
import ReactSelectField from '../Input/react-select-field';

const AddBorehole = (props: any) => {

    const {       
       onAddTrackFormSubmit,              
       onClose, 
       boreholesByProject,
       boreholeId,
       projectId
    } = props;    
    
    const [selectedProjectId, setSelectedProjectId] = useState<any>();
    const [selectedBoreholeIds, setSelectedBoreholeIds] = useState<any[]>();   
    
    const projectOptions = boreholesByProject?.map((project: any) => { return { value: project.id, label: project.name }} );
    const boreholeOptions = boreholesByProject?.find((borehole: any) => borehole.id == selectedProjectId)?.boreholes.map((borehole: any) => { return { value: borehole.id, label: borehole.name }} );    


    useEffect(() => {
      if (projectId && boreholesByProject?.length > 0) {
        setSelectedProjectId(+projectId);
      }      
    },
    [boreholesByProject, projectId]);


    useEffect(() => {          
      if (projectId && boreholeId && boreholeOptions?.length > 0) {                                
        const borehole = boreholeOptions.find((b: any) => b.value == +boreholeId);
        setSelectedBoreholeIds([borehole]);
      }
    },
    [boreholeOptions?.length, projectId, boreholeId]);

    const onOnClose = (e: any) => {
      e.preventDefault();
      onClose();
    };

    const onOnAddTrackFormSubmit = (e: any) => {
      e.preventDefault();      
      onAddTrackFormSubmit(selectedBoreholeIds?.map((borehole:any) => borehole.value ));
    }
    
    const anyChecked = (selectedBoreholeIds?.length ?? 0) > 0;

    const onProjectChange = (selectedItem: any) => {            
      setSelectedProjectId(selectedItem.value);
    };

    const onBoreholeChange = (selectedItem: any) => {                  
      setSelectedBoreholeIds(selectedItem);
    };
return(
  
        <form onSubmit={onAddTrackFormSubmit}>    
          
          <ReactSelectField label="Project" options={projectOptions} value={selectedProjectId} onChange={onProjectChange} />
          <ReactSelectField label="Boreholes" options={boreholeOptions} value={selectedBoreholeIds} onChange={onBoreholeChange} isMulti={true} />            
          
          <div className="buttons mt-3 sticky-buttons">
            <button className="button is-primary" onClick={onOnAddTrackFormSubmit} disabled={!anyChecked}>Ok</button>
            <button className="button" onClick={onOnClose}>Cancel</button>
          </div>
        </form>     
)};

export default AddBorehole;