import RightPanel from './right-panel';
import AddBorehole from './add-borehole';

const AddBoreholePanel = (props: any) => {
    const {       
       onAddTrackFormSubmit,            
       onClose,       
       boreholesByProject,
       projectId,
       boreholeId
    } = props;    

return(
  
  <RightPanel title="Add Borehole" icon="Correlation Icon">
    <div className="add-track-panel-contents">

        <AddBorehole
          onAddTrackFormSubmit={onAddTrackFormSubmit}          
          onClose={onClose}          
          boreholesByProject={boreholesByProject}
          projectId={projectId}
          boreholeId={boreholeId}
          />
       
    </div>
    </RightPanel>
)};

export default AddBoreholePanel;