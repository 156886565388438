import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; 
import useKeyboardShortcut from '../use-keyboard-shortcut/use-keyboard-shortcut';
import './image-viewer.scss';
import useWindowSize from '../useWindowResize';
import DepthTrack from './image-depth-track';
import CombinedImageViewer from './combined-image-viewer';
import ImageFilter from '../panels/image-filter';
import { useImageMetaDataQuery } from '../../services/imageApi';
import { useHistory, useParams } from 'react-router-dom';
import getConfig from "../../app/config";
import SubMenu from '../image-sub-menu';
import { AppDispatch, RootState } from '../../app/store';
import { getTemplates, 
  getTemplate,   
  addTemplate, 
  updateTemplate,
  deleteTemplate,
  setTrackWidth,
  setSelectedBuiltInTemplateId, 
  setSelectedUserTemplateId,
  setSelectedBoreholeTemplateId,
  setSelectedProjectTemplateId,
  setSelectedClientTemplateId,
  setNextTemplateId, 
  clearNotSaved,  
  addImageTracks,  
  getTrackDataForImageTrackTypes,
  setTrackImageFilter,
  clearTemplatesLoaded,
  clearTemplateLoading,
  clearTemplate,
  removeTrack,
  updateTrackOrder,
  scrollTrackIntoView,
  setTrackCollapsed,
  setViewer,
  addNewTemplate,
  setSelectedTemplateId,
  changeTemplateLevel,
  clearUrlTemplateId } from '../../features/borehole/templateSlice';
  import { useImageTypesQuery, useTrackTypesHierarchyQuery } from '../../services/trackTypeApi';
import MetaData from '../panels/meta-data';
import SaveAsDialog from '../dialogs/save-as-dialog';
import NotSavedDialog from '../dialogs/not-saved-dialog';
import AddTrackDialog from '../../features/borehole/add-track-dialog';
import AddTrackPanel from '../panels/add-track-panel'; 
import TrackOrderPanel from '../panels/track-order-panel';
import TemplatesPanel from '../panels/log-viewer/templates-panel';
import LeftSideMenu from '../side-menu/image-viewer-left-side-menu';
import Errors from '../errors';

const ImageViewer = (props: any) => {      

    const { setBorehole } = props;

    //const [imageZoom, setImageZoom] = useState<any>({});    
    const imageZoomRef = useRef<any>({});
    const [forceUpdate, setForceUpdate] = useState(Date.now());
    const [imageCenter, setImageCenter] = useState<any>({});    
    const [imageYOffset, setImageYOffset] = useState<any>({});
    const [ctrlKeyPressed, _setCtrlKeyPressed] = useState<boolean>(false);
    const ctrlKeyPressedRef = useRef(ctrlKeyPressed);
    const [mouseWheelZoom, _setMouseWheelZoom] = useState<boolean>(true);
    const mouseWheelZoomRef = useRef(mouseWheelZoom);
    const [startDepth, setStartDepth] = useState<number>(0);
    const [endDepth, setEndDepth] = useState<number>(27);
    const [showSeaDragon, setShowSeaDragon] = useState<boolean>(true);    
    const [metaData, setMetaData] = useState<any>();
    const [showMetaData, setShowMetaData] = useState<boolean>(false);
    const [showRightSidePanel, setShowRightSidePanel] = useState<boolean>(false);
    const [showAdjustmentsPanel, setShowAdjustmentsPanel] = useState<boolean>(false);
    const [showAddTrackPanel, setShowAddTrackPanel] = useState<boolean>(false);
    const [showReorderPanel, setShowReorderPanel] = useState<boolean>(false);
    const [currentTemplateLevel, setCurrentTemplateLevel] = useState<string | null>(null);

    const [selectedBuiltInTemplateIdValue, setSelectedBuiltInTemplateIdValue] = useState<any>(null);
    const [selectedUserTemplateIdValue, setSelectedUserTemplateIdValue] = useState<any>(null);
    const [selectedBoreholeTemplateIdValue, setSelectedBoreholeTemplateIdValue] = useState<any>(null);
    const [selectedProjectTemplateIdValue, setSelectedProjectTemplateIdValue] = useState<any>(null);
    const [selectedClientTemplateIdValue, setSelectedClientTemplateIdValue] = useState<any>(null);
    const [templateLevel, setTemplateLevel] = useState<any>();  
    const [editMode, setEditMode] = useState<boolean>(false);
    const [childLoading, setChildLoading] = useState(false);
    const [saveTemplateName, setSaveTemplateName] = useState<string>("");
    //const [tracks2, setTracks] = useState<any[]>([]);    
    const [selectedTrack, setSelectedTrack] = useState<any>();    

    const [saveAsTemplateLevel, setSaveAsTemplateLevel] = useState<any>();  

    const [showLeftSidePanel, setShowLeftSidePanel] = useState<boolean>(true);
    const [showDepthPanel, setShowDepthPanel] = useState<boolean>(false);
    const [showTemplatesPanel, setShowTemplatesPanel] = useState<boolean>(true);
    const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);
    const [moveError, setMoveError] = useState<any>(null);   
    
    const windowSize: any = useWindowSize();

    const apiUrl = getConfig().apiUrl;

    let { boreholeId, clientId, projectId, templateId } = useParams<any>();
    let history = useHistory();

    useKeyboardShortcut(['M'], () => { setMouseWheelZoom(!mouseWheelZoom) });

    const { data: trackTypesHierarchyData, isLoading: trackTypesHierarchyDataIsLoading } = useTrackTypesHierarchyQuery({ boreholeId, clientId, projectId });
    const { data: imageTypes, isLoading: imageTypesDataIsLoading } = useImageTypesQuery({ boreholeId, clientId, projectId, imageViewer: true });

    const dispatch: AppDispatch = useDispatch();
    const selectTemplates = (state: RootState) => state.templates;
    const { allTemplates, 
            templatesLoaded, 
            templatesLoading, 
            trackDataLoading,
            template, 
            notSaved, 
            selectedTemplateId, 
            selectedBuiltInTemplateId, 
            selectedUserTemplateId,
            selectedBoreholeTemplateId,
            selectedProjectTemplateId,
            selectedClientTemplateId,            
            canSaveTemplate, 
            canDeleteTemplate,
            selectedTemplate, 
            templateLoading,
            nextTemplateId,
            nextTemplateLevel,
            error,
            deepZoomMetaData,
            viewer,
            urlTemplateId
          } = useSelector(selectTemplates);

    const url = window.location.pathname.split('/').pop();

    const onSetImageViewer = (view: any) => {
      //hack to get round build issue on server      
      dispatch(setViewer(view));
    };

    const onClearTemplate = (emptyTemplate: any) => {
      //hack to get round build issue on server      
      dispatch(clearTemplate(emptyTemplate));
    };

    useEffect(() => {
      if (urlTemplateId) {        
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer/${urlTemplateId}`;
        dispatch(clearUrlTemplateId());
        history.push(url);
      }
    },[urlTemplateId]);

    useEffect(() => {
      if (templateId && allTemplates?.length) {
        const templateIdNum = +templateId;
        const selectedTemplateIdValue: any = allTemplates.find((o:any) => o.id == templateIdNum);
        // setSelectedBuiltInTemplateIdValue(null);
        // setSelectedUserTemplateIdValue(null);
        // setSelectedBoreholeTemplateIdValue(null); 
        // setSelectedProjectTemplateIdValue(null); 
        // setSelectedClientTemplateIdValue(selectedTemplateIdValue);
        
        if (!selectedTemplateIdValue) {
          return;
        }
        
        if (selectedTemplateIdValue.templateLevel) {
          setSaveAsTemplateLevel(selectedTemplateIdValue.templateLevel);
        }

        let currentLevel: string | null = null;
        setSelectedBuiltInTemplateIdValue(null);
        setSelectedUserTemplateIdValue(null);
        setSelectedBoreholeTemplateIdValue(null); 
        setSelectedProjectTemplateIdValue(null); 
        setSelectedClientTemplateIdValue(null);
                
        switch (selectedTemplateIdValue.templateLevel)
        {
          case 0:
            currentLevel = null;
            setSelectedBuiltInTemplateIdValue(templateIdNum);
            break;
          case 1:
            currentLevel = "User";
            setSelectedUserTemplateIdValue(templateIdNum);
            break;
          case 2:
            currentLevel = "Borehole";
            setSelectedBoreholeTemplateIdValue(templateIdNum);
            break;
          case 3:
            currentLevel = "Project";
            setSelectedProjectTemplateIdValue(templateIdNum);
            break;
          case 4:
            currentLevel = "Client";
            setSelectedClientTemplateIdValue(templateIdNum);
            break;
        }

        setCurrentTemplateLevel(currentLevel);
        dispatch(setSelectedTemplateId(templateIdNum));
      }
    },[templateId, allTemplates]);

    useEffect(() => {      
      if (selectedBuiltInTemplateId) {        
        const selectedTemplateIdValue: any = builtInTemplateOptions.find((o:any) => o.value == selectedBuiltInTemplateId);
        setSelectedBuiltInTemplateIdValue(selectedTemplateIdValue);
        setSelectedUserTemplateIdValue(null);
        setSelectedBoreholeTemplateIdValue(null); 
        setSelectedProjectTemplateIdValue(null); 
        setSelectedClientTemplateIdValue(null);
      } else {
        setSelectedBuiltInTemplateIdValue(null);
      }
    },[selectedBuiltInTemplateId]);

    useEffect(() => {
      if (selectedUserTemplateId) {
        const selectedTemplateIdValue: any = userTemplateOptions.find((o:any) => o.value == selectedUserTemplateId);
        setSelectedBuiltInTemplateIdValue(null);
        setSelectedUserTemplateIdValue(selectedTemplateIdValue);
        setSelectedBoreholeTemplateIdValue(null); 
        setSelectedProjectTemplateIdValue(null); 
        setSelectedClientTemplateIdValue(null);
        setSaveAsTemplateLevel(1);
        setCurrentTemplateLevel("User");
      } else {          
        setSelectedUserTemplateIdValue(null);
      }
    },[selectedUserTemplateId]);

    useEffect(() => {
      if (selectedBoreholeTemplateId) {
        const selectedTemplateIdValue: any = boreholeTemplateOptions.find((o:any) => o.value == selectedBoreholeTemplateId);
        setSelectedBuiltInTemplateIdValue(null);
        setSelectedUserTemplateIdValue(null);
        setSelectedBoreholeTemplateIdValue(selectedTemplateIdValue); 
        setSelectedProjectTemplateIdValue(null); 
        setSelectedClientTemplateIdValue(null);
        setSaveAsTemplateLevel(2);
        setCurrentTemplateLevel("Borehole");
      }
    },[selectedBoreholeTemplateId]);

    useEffect(() => {
      if (selectedProjectTemplateId) {
        const selectedTemplateIdValue: any = projectTemplateOptions.find((o:any) => o.value == selectedProjectTemplateId);
        setSelectedBuiltInTemplateIdValue(null);
        setSelectedUserTemplateIdValue(null);
        setSelectedBoreholeTemplateIdValue(null); 
        setSelectedProjectTemplateIdValue(selectedTemplateIdValue); 
        setSelectedClientTemplateIdValue(null);
        setSaveAsTemplateLevel(3);
        setCurrentTemplateLevel("Project");
      }
    },[selectedProjectTemplateId]);

    useEffect(() => {
      if (selectedClientTemplateId) {
        const selectedTemplateIdValue: any = clientTemplateOptions.find((o:any) => o.value == selectedClientTemplateId);
        setSelectedBuiltInTemplateIdValue(null);
        setSelectedUserTemplateIdValue(null);
        setSelectedBoreholeTemplateIdValue(null); 
        setSelectedProjectTemplateIdValue(null); 
        setSelectedClientTemplateIdValue(selectedTemplateIdValue);
        setSaveAsTemplateLevel(4);
        setCurrentTemplateLevel("Client");
      }
    },[selectedClientTemplateId]);

    useEffect(() => {
      onSetImageViewer("Image");
      onClearTemplate({});

      return function cleanup() {
        onClearTemplate({});
      };
    },[]);

    useEffect(() => {
      dispatch(clearTemplatesLoaded());
      dispatch(getTemplates({ templateType:1, clientId, projectId, boreholeId }));
    }, [url]);

    useEffect(() => {      
      if (templatesLoaded && allTemplates && allTemplates.length > 0) {
        //const templateId = allTemplates[0].id;
        //selectBuiltInTemplate(templateId);              
      }
    },[templatesLoaded]);  

    useEffect(() => {      
      if (selectedTemplateId && viewer == "Image") {
        dispatch(clearTemplateLoading());
        dispatch(getTemplate(selectedTemplateId));          
      } else {
        setSelectedBuiltInTemplateIdValue(null);
        setSelectedUserTemplateIdValue(null);
        setSelectedBoreholeTemplateIdValue(null);
        setSelectedProjectTemplateIdValue(null);
        setSelectedClientTemplateIdValue(null);
      }
    },[selectedTemplateId]);

    useEffect(() => {      
      if (selectedTemplateId && !templateLoading && template?.tracks?.length && viewer == "Image") {
        ///const newTracks = Array.from(template.tracks.map((track: any) => { return { id: track.id, imageType: track.imageType, label: track.displayName }}));        
        const newImageTypes = template.tracks.map((track: any) => { return track.imageType });
        //setTracks(newTracks);

        let newImageZoom: any = {};        
        newImageTypes.forEach((imageType: any) => { 
          newImageZoom[imageType.toString()] = 1;
        });
                
        //setImageZoom(newImageZoom);
        imageZoomRef.current = newImageZoom;
        
        dispatch(getTrackDataForImageTrackTypes({ 
          clientId, 
          projectId,
          boreholeId,
          imageTypes: newImageTypes
           }));
      }   
    },[selectedTemplateId, templateLoading, template?.tracks?.length]);

    const onChangeBuiltInTemplate = (e: any) => {           
      if (notSaved) {         
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 0}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer/${e.value}`;
        history.push(url);
        //selectBuiltInTemplate(e.value);
      }
    };

    const onChangeUserTemplate = (e: any) => {     
      if (notSaved) {         
        
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 1}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer/${e.value}`;
        history.push(url);
        //selectUserTemplate(e.value);
      }
    };

    const onChangeBoreholeTemplate = (e: any) => {     
      if (notSaved) {         
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 2}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer/${e.value}`;
        history.push(url);
        //selectBoreholeTemplate(e.value);
      }
    };

    const onChangeProjectTemplate = (e: any) => {     
      if (notSaved) {         
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 3}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer/${e.value}`;
        history.push(url);
        //selectProjectTemplate(e.value);
      }
    };

    const onChangeClientTemplate = (e: any) => {     
      if (notSaved) {         
        dispatch(setNextTemplateId({nextTemplateId: e.value, nextTemplateLevel: 4}));
        setShowNotSavedModal(true);
      } else {
        const url = `/client/${clientId}/project/${projectId}/borehole/${boreholeId}/image-viewer/${e.value}`;
        history.push(url);
        //selectClientTemplate(e.value);
      }
    };

    const selectBuiltInTemplate = (templateId: string) => {      
      const selectedTemplateIdValue2: any = builtInTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(selectedTemplateIdValue2);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedBuiltInTemplateId(templateId)); 
    }

    const selectUserTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = userTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);      
      setSelectedUserTemplateIdValue(selectedTemplateIdValue2);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedUserTemplateId(templateId)); 
    }

    const selectBoreholeTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = boreholeTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(selectedTemplateIdValue2);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedBoreholeTemplateId(templateId));       
    }

    const selectProjectTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = projectTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(selectedTemplateIdValue2);
      setSelectedClientTemplateIdValue(null);
      dispatch(setSelectedProjectTemplateId(templateId));       
    }

    const selectClientTemplate = (templateId: string) => {
      const selectedTemplateIdValue2: any = clientTemplateOptions.find((o:any) => o.value == templateId);
      setSelectedBuiltInTemplateIdValue(null);
      setSelectedUserTemplateIdValue(null);
      setSelectedBoreholeTemplateIdValue(null);
      setSelectedProjectTemplateIdValue(null);
      setSelectedClientTemplateIdValue(selectedTemplateIdValue2);
      dispatch(setSelectedClientTemplateId(templateId)); 
    }

    const onEdit = () => {
      setEditMode(!editMode);
    };

    const onSave = () => {
      dispatch(updateTemplate({...template, id: selectedTemplateId }));
      setEditMode(false);
    };
  
    const onSaveAs = () => {
      debugger;
      let copyNameTemplate = '';      
      if (template?.name.endsWith("Copy")){
        copyNameTemplate = template?.name + " 1";
      } else if (template?.name) {
        copyNameTemplate = template?.name + " Copy";
      }      
      let copyName = copyNameTemplate;      
      let copyCount = 1;
      while (allTemplates.find((t: any) => t.name.toLowerCase() === copyName.toLowerCase().trim())) {
        copyName = copyNameTemplate + ` ${copyCount}`;
        copyCount++;
      }

      setSaveTemplateName(copyName);
      setSaveTemplateNameError(null);
      setShowSaveAsModal(true);
      setEditMode(false);
    };

    const onDeleteTemplate = () => {
      confirmAlert({
        title: 'Confirm Delete Template',
        message: `Are you sure you want to delete template?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {              
              dispatch(deleteTemplate({templateId: selectedTemplateId, level: saveAsTemplateLevel }));      
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    };
    
    const setCtrlKeyPressed = (data: any) => {
      ctrlKeyPressedRef.current = data;
      _setCtrlKeyPressed(data);
    };
    
    useEffect(() => {
      window.addEventListener('wheel', function(event) {

        if (event.ctrlKey) {
          event.preventDefault();
        }
      }, { passive: false });
    }, []);    

    useEffect(() => {
      if (deepZoomMetaData?.boreholeName) {        
        setBorehole({name: deepZoomMetaData?.boreholeName, id: boreholeId})
      }
    }, [deepZoomMetaData?.boreholeName])

    const setMouseWheelZoom = (data: any) => {
      mouseWheelZoomRef.current = data;
      _setMouseWheelZoom(data);
    };

    const onKeyDown = (event: any) => {    
      setCtrlKeyPressed(event.code === "ControlLeft" || event.code === "ControlRight");
    }
  
    const onKeyUp = (event: any) => {    
      if (event.code === "ControlLeft" || event.code === "ControlRight") {
        setCtrlKeyPressed(false);
      }
    }

    const ref = useRef<any>();

    useEffect(() => {
      function handleKeyDown(e: any) {
        // check if keydown was contained in target div
        if (!ref.current) { // || ref.current.contains(e.target)
          return;
        }
  
        // Emit event to parent component
        onKeyDown(e);
      }
  
      function handleKeyUp(e: any) {
        // check if keydown was contained in target div      
        if (!ref.current) { // || ref.current.contains(e.target)
          return;
        }
  
        // Emit event to parent component
        onKeyUp(e);
      }
  
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
    
      return function cleanup() {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);  
      };
    }, []);

    const imageMoved = (arg: any, applyToAll: boolean = false) => {   
      var tracks3 = template?.tracks;
      const otherTracks = applyToAll ? tracks3 : tracks3.filter((t: any) => t.imageType && t.imageType != arg.imageType)      
      if (arg.zoom) {
        console.log("applyToAll", applyToAll);
        console.log("arg.imageType", arg.imageType);
        console.log("imageMoved arg.zoom", arg.zoom);

        console.log("old imageZoomRef.current", imageZoomRef.current);
        let newImageZoom: any = {...imageZoomRef.current};
        tracks3.forEach((track: any) => {
          if (track.imageType == null) {
            debugger;
          }

          console.log("zoom track", track.imageType.toString());
          newImageZoom[track.imageType.toString()] = arg.zoom;
        });
        
        //console.log("newImageZoom", newImageZoom);
        imageZoomRef.current = newImageZoom;

        if (arg.zoom == 1) {
          setForceUpdate(Date.now()); 
        }
        
        console.log("imageZoomRef.current", imageZoomRef.current);
        //setImageZoom(newImageZoom);
      }

      if (arg.center) {
        let newImageCenter: any = {...imageCenter};
        otherTracks.forEach((track: any) => {
          newImageCenter[track.imageType.toString()] = arg.center;
        });

        setImageCenter(newImageCenter);
      }

      if (arg.yOffset != null) {
        let newImageYOffset: any = {...imageYOffset};
        otherTracks.forEach((track: any) => {
          newImageYOffset[track.imageType.toString()] = arg.yOffset;
        });

        setImageYOffset(newImageYOffset);
      }
    };

      const updateDepth = (startDepth: any, endDepth: any) => {
        setStartDepth(startDepth);        
        setEndDepth(endDepth);
      };

      const trackHeight = windowSize.height - 178;      

      const zoomOutPastMaximum = () => {
        setShowSeaDragon(false);
      };

      const zoomInPastMinimum = () => {
        setShowSeaDragon(true);
      };
      
      // const defaultTemplates = templates.filter((t:any) => t.cannotOverwrite);
      // let userTemplates = templates.filter((t:any) => !t.cannotOverwrite);
      // userTemplates.sort((a: any, b: any) => {
      //   return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      // });
      
      // const templateOptions:any =  [
      //   {
      //     label: "Fixed",
      //     options: defaultTemplates.map((t: any) => { return { label: t.name, value: t.id}})
      //   },
      //   { label: "User",
      //   options: userTemplates.map((t: any) => { return { label: t.name, value: t.id}})
      // }
      // ];

      const builtInTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 0).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
      const userTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 1).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
      const boreholeTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 2).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
      const projectTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 3).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});
      const clientTemplateOptions: any = allTemplates.filter((t:any) => t.templateLevel === 4).map((t: any) => { return { label: t.name, value: t.id, isDisabled: !t.containsData}});

      const onShowMetaData = () => {
        setShowMetaData(!showMetaData);
      };

      const [showSaveAsModal, setShowSaveAsModal] = useState<boolean>(false);
      const [showNotSavedModal, setShowNotSavedModal] = useState<boolean>(false);  
      const [saveTemplateNameError, setSaveTemplateNameError] = useState<string | null>(null);
      const [selectedTrackType, setSelectedTrackType] = useState<any>();
      const [checkedTrackTypes, setCheckedTrackTypes] = useState<Array<string>>([]);
      const [selectedTemplateTrackType, setSelectedTemplateTrackType] = useState(1);
      const [selectedTrackCategory, setSelectedTrackCategory] = useState<any>(null);
      const [checkedImageTypes, setCheckedImageTypes] = useState<Array<number>>([]);

      const handleCloseSaveAsModal = () => {
        setShowSaveAsModal(false);
      };

      const onSaveAsFormSubmit = () => {        
        handleOkSaveAsModal();
      };

      const handleOkSaveAsModal = () => {
        // check no other names exist
        if (allTemplates.find((t: any) => t.name.toLowerCase() === saveTemplateName.toLowerCase().trim())) {
          //setSaveTemplateNameError("Cannot have duplicate names!");
        } else {        
          saveAs();
          setShowSaveAsModal(false);        
        }
      };

      const saveAs = () => {            
        const changeToNew = nextTemplateId == null;
        dispatch(addTemplate({ ...template, name: saveTemplateName, changeToNew, templateType: 1, templateLevel: +templateLevel, clientId, projectId, boreholeId }));
      };
  
      const handleCloseNotSavedModal = () => {
        setShowNotSavedModal(false);
      };
  
      const handleYesNotSavedModal = () => {
        setShowNotSavedModal(false);
  
        if (canSaveTemplate) {
          onSave();
          if (nextTemplateId) {
              switch (nextTemplateLevel) {
                case 0: 
                  selectBuiltInTemplate(nextTemplateId);
                  break;
                case 1: 
                  selectUserTemplate(nextTemplateId);
                  break;
                case 2: 
                  selectBoreholeTemplate(nextTemplateId);
                  break;
                case 3: 
                  selectProjectTemplate(nextTemplateId);
                  break;
                case 4: 
                  selectClientTemplate(nextTemplateId);
                  break;
              }
            dispatch(setNextTemplateId(null));
          } else {
            setSaveTemplateName("");
            setSaveAsTemplateLevel(1);      
            setSaveTemplateNameError(null);
            setEditMode(false);
            dispatch(addNewTemplate());
          } 
        } else {
          onSaveAs();
        }
      };
  
      const handleNoNotSavedModal = () => {
        setShowNotSavedModal(false);
        if (nextTemplateId) {
            switch (nextTemplateLevel) {
              case 0: 
                selectBuiltInTemplate(nextTemplateId);
                break;
              case 1: 
                selectUserTemplate(nextTemplateId);
                break;
              case 2: 
                selectBoreholeTemplate(nextTemplateId);
                break;
              case 3: 
                selectProjectTemplate(nextTemplateId);
                break;
              case 4: 
                selectClientTemplate(nextTemplateId);
                break;
            }
          dispatch(setNextTemplateId(null));
          dispatch(clearNotSaved());
        } else {
          setSaveTemplateName("");
          setSaveAsTemplateLevel(1);      
          setSaveTemplateNameError(null);
          setEditMode(false);
          dispatch(addNewTemplate());
        }
      };
  
      const onAddTrack = () => {
        onShowAddTrackPanel();
      };
  
      const handleCloseAddTrackModal = () => {
        onHideAddTrackPanel();
        setCheckedTrackTypes([]);
        setCheckedImageTypes([]);
      };
  
      const handleOkAddTrackModal = () => {
        onHideAddTrackPanel();
                
        if (selectedTemplateTrackType === 1) { //image
          const selectedTrackTypes = checkedImageTypes.map((imageTypeValue: any) => {          
            const imageType = imageTypes.imageTypes.find((imageType: any) => imageType.value == imageTypeValue);
            return { imageType: imageTypeValue, displayName: imageType?.name, legendName: imageType?.legendName, legendRollover: imageType?.legendRollover }
          });
          dispatch(addImageTracks({imageTrackTypes: selectedTrackTypes, boreholeIds: [boreholeId]}));

          dispatch(getTrackDataForImageTrackTypes({ 
            clientId, 
            projectId,
            boreholeId,
            imageTypes: selectedTrackTypes.map((track: any) => track.imageType) }));          
        }

        setCheckedTrackTypes([]);
        setCheckedImageTypes([]);
      };
  
      const onAddTrackFormSubmit = () => {
        handleOkAddTrackModal();
      };

      const sortedTrackData = template?.tracks?.filter((t: any) => { return t.imageType}).map((track: any) => { 
        var trackTrackData = deepZoomMetaData[track.imageType.toString()];
        
        return {
          id: track.id,
          label: track.displayName,
          imageType: track.imageType,
          metaData: trackTrackData,
          imageFilter: track.imageFilter,
          canAdjustImage: trackTrackData?.canAdjustImage,
          scrollIntoView: track.scrollIntoView,
          collapsed: track.collapsed          
        }
      });



      const onShowAdjustments = () => {
        if (!showRightSidePanel) {
          setShowRightSidePanel(true);
        }

        setShowAddTrackPanel(false);
        setShowAdjustmentsPanel(true);
        setShowReorderPanel(false);
      };

      const onSetShowReorderPanel = () => {
        if (!showRightSidePanel) {
          setShowRightSidePanel(true);
        }

        setShowReorderPanel(true);
        setShowAddTrackPanel(false);
        setShowAdjustmentsPanel(false);
      };

      const onHideAdjustmentsPanel = () => {
        setShowAdjustmentsPanel(false);
      };

      const onShowAddTrackPanel = () => {
        if (!showRightSidePanel) {
          setShowRightSidePanel(true);
        }

        setShowAddTrackPanel(true);
        setShowAdjustmentsPanel(false);
        setShowReorderPanel(false);
      };

      const onHideAddTrackPanel = () => {
        setShowRightSidePanel(false);
      };

      const onCloseRightSidePanel = () => {
        setShowAddTrackPanel(false);
        setShowAdjustmentsPanel(false);
        setShowReorderPanel(false);
        setShowRightSidePanel(false);
        setSelectedTrack(null);
      };

      const onShowHideRightSidePanel = () => {    
        if (showRightSidePanel)  {
          setShowAddTrackPanel(false);
          setShowAdjustmentsPanel(false);
          setShowReorderPanel(false);
          setShowRightSidePanel(false);
          setSelectedTrack(null);
        } else {
          setShowAddTrackPanel(true);
        }
  
        setShowRightSidePanel(!showRightSidePanel);
      };

      const onTrackHeaderClick = (track: any) => {
        if (track.id === selectedTrack?.id) {
          setSelectedTrack(null); 
          onCloseRightSidePanel();
        } else {
          setSelectedTrack(track);
          dispatch(scrollTrackIntoView({ trackId: track.id }));
          if (track.canAdjustImage) {
            if (!showRightSidePanel) {
              setShowRightSidePanel(true);
            }

            if (!showAdjustmentsPanel) {
              onShowAdjustments();
            }          
          }
        }
      };

      const onSetSelectedTrack = (track: any) => {        
        setSelectedTrack(track);
        if (!track) {
          return;
        }

        dispatch(scrollTrackIntoView({ trackId: track.id }));
        if (track.canAdjustImage) {
          if (!showRightSidePanel) {
            setShowRightSidePanel(true);
          }

          if (!showAdjustmentsPanel) {
            onShowAdjustments();
          }          
        }        
      };

      const onSetTrackCollapsed = (trackId: string, collapsed: boolean) => {
        dispatch(setTrackCollapsed({ trackId, collapsed }));
      }

      const onSetTrackImageFilter = (filter: string, trackId?: string) => {
        if (trackId || selectedTrack) {    
          dispatch(setTrackImageFilter({templateTrackId: trackId ?? selectedTrack.id, filter})); 
        }
      };

      const onRemoveTrack = (trackTypeId: any, displayName: string) => {
        confirmAlert({
          title: 'Confirm Remove track',
          message: `Are you sure you want to remove track ${displayName}?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                dispatch(removeTrack(trackTypeId));
              }
            },
            {
              label: 'No',
              onClick: () => {}
            }
          ]
        });
      };    
      
      const onUpdateTrackOrder = (sourceIndex: number, destinationIndex: number) => {
        dispatch(updateTrackOrder({sourceIndex, destinationIndex}));
      };

      const onShowHideLeftSidePanel = () => {    
        if (showLeftSidePanel)  {
          setShowDepthPanel(false);
          setShowTemplatesPanel(false);
          setShowInfoPanel(false);
        } else {
          setShowTemplatesPanel(true);
        }
  
        setShowLeftSidePanel(!showLeftSidePanel);
      };
  
      const onShowDepthPanel = () => {      
        if (!showLeftSidePanel) {
          setShowLeftSidePanel(true);
        }
  
        setShowDepthPanel(true);
        setShowTemplatesPanel(false);
        setShowInfoPanel(false);
      };
  
      const onShowTemplatesPanel = () => {      
        if (!showLeftSidePanel) {
          setShowLeftSidePanel(true);
        }
  
        setShowDepthPanel(false);
        setShowTemplatesPanel(true);
        setShowInfoPanel(false);
      };
  
      const onShowInfoPanel = () => {      
        if (!showLeftSidePanel) {
          setShowLeftSidePanel(true);
        }
  
        setShowDepthPanel(false);
        setShowTemplatesPanel(false);
        setShowInfoPanel(true);
      };

      const deselectAllTracks = () => {
        setSelectedTrack(null); 
        onCloseRightSidePanel();
      };

      const onNewTemplate = () => {
        if (notSaved) {
          dispatch(setNextTemplateId({nextTemplateId: null}));
          setShowNotSavedModal(true);
        } else {
          setSaveTemplateName("");
          setSaveAsTemplateLevel(1);      
          setSaveTemplateNameError(null);
          // setShowSaveAsModal(true);
          setEditMode(false);
          dispatch(addNewTemplate());
        }
      };

      const moveTemplateLevel = (newLevel: any) => {
        if (allTemplates.find((t: any) => t.templateLevel == newLevel && t.name.toLowerCase() === saveTemplateName.toLowerCase().trim())) {
          setMoveError("Cannot have duplicate names in the same level.");
        } else {
          setMoveError(null);
          dispatch(changeTemplateLevel({id: selectedTemplateId, templateLevel: newLevel, clientId, projectId, boreholeId }));
        }
      };

// console.log("sortedTrackData", sortedTrackData);
// console.log("template", template);
// console.log("tracks", tracks);
// console.log("imageMetaData", imageMetaData);

      return (
        <div ref={imageZoomRef}>
          <div className="sidebar" style={{width: showLeftSidePanel ? 300 : 0 }}>
            {showInfoPanel && <MetaData metaData={metaData}/> }
            {showTemplatesPanel && <TemplatesPanel 
              currentTemplate={template}
              currentTemplateLevel={currentTemplateLevel}
              builtInTemplateOptions={builtInTemplateOptions}
              userTemplateOptions={userTemplateOptions}
              boreholeTemplateOptions={boreholeTemplateOptions}
              projectTemplateOptions={projectTemplateOptions}
              clientTemplateOptions={clientTemplateOptions}
              onChangeBuiltInTemplate={onChangeBuiltInTemplate}
              onChangeUserTemplate={onChangeUserTemplate}
              onChangeBoreholeTemplate={onChangeBoreholeTemplate}
              onChangeProjectTemplate={onChangeProjectTemplate}
              onChangeClientTemplate={onChangeClientTemplate}
              selectedBuiltInTemplateIdValue={selectedBuiltInTemplateIdValue}
              selectedUserTemplateIdValue={selectedUserTemplateIdValue}
              selectedBoreholeTemplateIdValue={selectedBoreholeTemplateIdValue}
              selectedProjectTemplateIdValue={selectedProjectTemplateIdValue}
              selectedClientTemplateIdValue={selectedClientTemplateIdValue}
              childLoading={childLoading}
              onSave={onSave}
              canSaveTemplate={canSaveTemplate}
              canDeleteTemplate={canDeleteTemplate}
              notSaved={notSaved}
              onSaveAs={onSaveAs}
              deleteTemplate={onDeleteTemplate}
              onNewTemplate={onNewTemplate}
              mouseWheelZoom={mouseWheelZoom}
              setMouseWheelZoom={setMouseWheelZoom}
              selectedTemplateId={templateId}
              moveTemplateLevel={moveTemplateLevel}
              moveError={moveError}
              /> }
          </div>
          <LeftSideMenu
            showLeftSidePanel={showLeftSidePanel}
            onShowHideLeftSidePanel={onShowHideLeftSidePanel}            
            showTemplatesPanel={showTemplatesPanel}
            onShowTemplatesPanel={onShowTemplatesPanel}
            notSaved={notSaved}
            showInfoPanel={showInfoPanel}
            onShowInfoPanel={onShowInfoPanel}
            templateSelected={selectedTemplateId}
          />
               
          <div style={{ marginLeft: showLeftSidePanel ? 350 : 50, marginRight: showRightSidePanel ? 477 : 50 }} className="sidebar-main">

            <SaveAsDialog 
                showSaveAsModal={showSaveAsModal}
                handleCloseSaveAsModal={handleCloseSaveAsModal}
                handleOkSaveAsModal={handleOkSaveAsModal}
                onSaveAsFormSubmit={onSaveAsFormSubmit}
                saveTemplateName={saveTemplateName}
                setSaveTemplateName={setSaveTemplateName}
                saveTemplateNameError={saveTemplateNameError}
                templateLevel={templateLevel} 
                setTemplateLevel={setTemplateLevel}
              />

            <NotSavedDialog 
                showNotSavedModal={showNotSavedModal}
                handleCloseNotSavedModal={handleCloseNotSavedModal}
                handleYesNotSavedModal={handleYesNotSavedModal}
                handleNoNotSavedModal={handleNoNotSavedModal}
              />
       <div className='image-viewer-header'>
          <>
            {setMouseWheelZoom &&
              <>
              {/* <nav className="level">
                <div className="level-left">
                  <div className="level-item white" title='Or use the M key to swtich'>
                    Mouse wheel Control:
                  </div>
                  <div className="level-item level-scroll">
                    <label className="mr-1" onClick={(e: any) => setMouseWheelZoom(true)}>zoom</label>                  
                  </div>   
                  <div className="level-item level-scroll">
                    <input type="checkbox" className="switch is-rounded is-outlined" checked={!mouseWheelZoom} />
                    <label onClick={(e: any) => setMouseWheelZoom(!mouseWheelZoom)}></label>
                  </div>
                  <div className="level-item level-scroll">
                    <label onClick={(e: any) => setMouseWheelZoom(false)}>scroll</label>
                  </div>
                </div>       
              </nav>    */}
              <div className='mouse-wheel-control'>
                <div className='mouse-wheel-control-text'>
                  Mouse wheel Control:
                </div>
                <div className='mouse-wheel-control-control'>
                  <label className="mr-1" onClick={(e: any) => setMouseWheelZoom(true)}>zoom</label>                  

                  <input type="checkbox" className="switch is-rounded is-outlined" checked={!mouseWheelZoom} />
                  <label className="mouse-wheel-control-switch" onClick={(e: any) => setMouseWheelZoom(!mouseWheelZoom)}></label>

                  <label onClick={(e: any) => setMouseWheelZoom(false)}>scroll</label>
                </div>
            </div>
              </>
            }      
          </>
          <div className='borehole-name-container'>
            <h2 className="borehole-name" onClick={deselectAllTracks}>{deepZoomMetaData?.boreholeName}</h2>      
          </div>
      </div>

    {/* <div className='image-viewer-header'>
    
            {setMouseWheelZoom &&
              <div>
    
                    Mouse wheel Control:
    
                    <label className="mr-1" onClick={(e: any) => setMouseWheelZoom(true)}>zoom</label>                  
    
                    <input type="checkbox" className="switch is-rounded is-outlined" checked={!mouseWheelZoom} />
                    <label onClick={(e: any) => setMouseWheelZoom(!mouseWheelZoom)}></label>
    
                    <label onClick={(e: any) => setMouseWheelZoom(false)}>scroll</label>
    
              </div>
            }      
    
    
            <h2 className="mt-2 borehole-name" onClick={deselectAllTracks}>{imageMetaData?.boreholeName}</h2>      
    
      </div> */}


      <Errors error={error}/>
          
            {startDepth != null && endDepth != null && <section>
              <div style={{ backgroundColor:"black"}}>
                <div ref={ref} className="image-track-container">
                <DepthTrack 
                      startDepth={startDepth} 
                      endDepth={endDepth} 
                      depthUnit={1} 
                      minHeaderHeight={1}
                      editMode={false}                 
                      trackTop={53}
                      trackHeight={trackHeight}
                      depthMajorIntervals={8} 
                      //setDialogMajorIntervals={setDepthMajorIntervals}
                      showDepthGridLines={true}
                      //setShowDepthGridlines={setShowDepthGridlines}
                      depthMinorIntervals={2}
                      //setDepthMinorIntervals={setDepthMinorIntervals}
                      showDepthMinorIntervals={false}
                      //setShowDepthMinorIntervals={setShowDepthMinorIntervals} 
                      />
                <div className="columns flex-container" style={{ overflowY: "hidden" }}>
                  {(sortedTrackData || []).map((track: any, index: number) => (                
                        <div key={track.id} className="column flex-item">
                          <div className={`columns track-label-container ${selectedTrack?.id === track.id ? "highlighted-track" : ""}`} 
                                onClick={() => onTrackHeaderClick(track)}>
                            <div className="column is-1"></div>
                            <div className={`column is-size-5 has-text-centered track-label white-label`}>
                              {track.label} {track.metaData?.needsRegenerating && <span className="material-icons warning-icon" title={"Need regenerating"}>warning</span>}
                            </div>
                            <div className="column is-1 no-padding">
                              {selectedTrack?.id === track.id &&                              
                                <span className="material-icons has-text-right is-vcentered white-icon" title="Remove" onClick={() => onRemoveTrack(track.id, track.label)}>
                                  delete
                                </span>     
                              }
                             </div>
                        </div>
                        <CombinedImageViewer 
                          key={index}
                          label={track.label}
                          apiUrl={apiUrl}
                          clientId={clientId}
                          projectId={projectId}
                          boreholeId={boreholeId}
                          id={track.id}
                          moved={imageMoved} 
                          zoom={imageZoomRef.current[track.imageType.toString()]} 
                          center={imageCenter[track.imageType.toString()]} 
                          yOffset={imageYOffset[track.imageType.toString()]}
                          setYOffset={setImageYOffset}
                          ctrlKeyPressed={ctrlKeyPressed} 
                          mouseWheelZoom={mouseWheelZoom} 
                          trackHeight={trackHeight} 
                          updateDepth={index === 0 ? updateDepth : null} 
                          zoomOutPastMaximum={zoomOutPastMaximum}
                          showSeaDragon={showSeaDragon}
                          setMetaData={setMetaData}
                          imageType={track.imageType}
                          metaData={track.metaData}
                          setSelectedTrack={onSetSelectedTrack}
                          imageFilter={track.imageFilter}
                          removeTrack={onRemoveTrack}
                          scrollIntoView={track.scrollIntoView}
                          />
                    </div>
                  ))}
                </div>                
              </div>
              </div>            
            </section>}
          </div>
          <div className="right-side-menu-panel" style={{ marginRight: showRightSidePanel ? 427 : 0 }}>
              <button className="button is-tab button-icon" onClick={onShowHideRightSidePanel} title="Close">
                {showRightSidePanel ? <img src={"/images/icons/Atlas Open 2 Icon.svg"} /> : <img src={"/images/icons/Atlas Open 1 Icon.svg"} />}
              </button>
              <button className={`button is-tab button-icon mt-1 ${showAddTrackPanel ? "highlighted" : ""}`} onClick={onAddTrack} title="Add Track">
                <img src={"/images/icons/Atlas Add Track Icon.svg"} />
              </button>              
              <button className={`button is-tab button-icon mt-1 ${showReorderPanel ? "highlighted" : ""}`} onClick={onSetShowReorderPanel} title="Reorder">
                <img src={"/images/icons/Atlas Reorder Icon.svg"} />
              </button>
              <button className={`button is-tab button-icon mt-1 ${showAdjustmentsPanel ? "highlighted" : ""}`} onClick={onShowAdjustments} title="Adjustments">
                <img src={"/images/icons/Atlas Adjustment Icon.svg"} />
              </button>              
          </div>
          <div className="right-side-panel" style={{width: showRightSidePanel ? 427 : 0 }}>
          {showAddTrackPanel && 
                <AddTrackPanel
                  handleCloseAddTrackModal={handleCloseAddTrackModal}
                  handleOkAddTrackModal={handleOkAddTrackModal}
                  onAddTrackFormSubmit={onAddTrackFormSubmit}
                  selectedTrackType={selectedTrackType} 
                  setSelectedTrackType={setSelectedTrackType}
                  trackTypesData={trackTypesHierarchyData?.trackTypeCategories} 
                  trackTypesDataIsLoading={trackTypesHierarchyDataIsLoading}                            
                  checkedTrackTypes={checkedTrackTypes}
                  setCheckedTrackTypes={setCheckedTrackTypes} 
                  selectedTemplateTrackType={selectedTemplateTrackType}
                  setSelectedTemplateTrackType={setSelectedTemplateTrackType}
                  checkedImageTypes={checkedImageTypes}
                  setCheckedImageTypes={setCheckedImageTypes}
                  imageTypes={imageTypes?.imageTypes}
                  selectedTrackCategory={selectedTrackCategory}
                  setSelectedTrackCategory={setSelectedTrackCategory}
                  allowedTrackCategories={[1]}
                  onClose={onHideAddTrackPanel}
                />              
            }
            {showAdjustmentsPanel && 
              <ImageFilter 
                    setFilter={onSetTrackImageFilter} 
                    onClose={onHideAdjustmentsPanel} 
                    filter={selectedTrack?.imageFilter} 
                    hasSelectedTrack={!!selectedTrack} 
                    tracks={sortedTrackData} 
                    setSelectedTrack={onSetSelectedTrack} 
                    setTrackCollapsed={onSetTrackCollapsed}
                    selectedTrack={selectedTrack} />            
            }           
            {showReorderPanel && <TrackOrderPanel tracks={sortedTrackData} updateTrackOrder={onUpdateTrackOrder} />}
          </div>
        </div>
      );    
}

export default ImageViewer;