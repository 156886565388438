import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import { useAppSelector } from '../../../app/hooks';
import { selectUser } from '../../../services/accountSlice';
import { useGetResourcesQuery } from '../../../services/roleApi';
import { useProjectsQuery } from '../../../services/projectApi';
import { useGetBoreholesQuery } from '../../../services/boreholeApi';
import Form from './form';

const RoleContainer = ({ title, roleIsLoading, role, onSave, isLoading, error, readOnly, userPermissions }:any) => {
    
    const [name, setName] = useState('');
    const [checkedResources, setCheckedResources] = useState<Array<string>>([]);
    const [builtInRole, setBuiltInRole] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState<any[]>([]);
    const [sourceProjects, setSourceProjects] = useState<any[]>([]);
    const [showSpecificProjects, setShowSpecificProjects] = useState(false);

    const currentUser = useAppSelector(selectUser);

    const { data: resources, isLoading: resourcesIsLoading } = useGetResourcesQuery();
    
    const { data: projects, isLoading: projectsIsLoading } = useProjectsQuery(currentUser?.clientId ?? "", {
        skip: !currentUser?.clientId && !showSpecificProjects
      });

    useEffect(() => {
        if (!roleIsLoading && role) {
            setName(role.name);         
            setBuiltInRole(role.isBuiltIn);
            setCheckedResources(role.resources?.filter((c: any) => c.access === 1).map((c: any) => c.resourceId) ?? []);
            setShowSpecificProjects(role.showSpecificProjects);
        }
    },[roleIsLoading, role]);

    useEffect(() => {
        if (userPermissions) {            
            setShowSpecificProjects(userPermissions.showSpecificProjects);
        }
    },[userPermissions]);

    useEffect(() => {
        if (!projectsIsLoading && projects) {
            const orderedProjects = Array.from(projects?.projects);
            orderProjects(orderedProjects);
            setSourceProjects(orderedProjects);
        }
    },[projectsIsLoading, projects]);


    useEffect(() => {
        if (!roleIsLoading && role && !projectsIsLoading && projects) {
            const projectsCopy = Array.from(projects?.projects);
            const existingProjects = Array.from(role.projects);
            // populate names
            const existingProjectsWithName = existingProjects.map((existringProject: any) => {                 
                const foundProject :any = projectsCopy.find((project: any) => project.id == existringProject.id);
                return {...existringProject, name: foundProject.name };
            });
            
            orderProjects(existingProjectsWithName);
            setSelectedProjects(existingProjectsWithName);
            const selectedProjectIds = role.projects.map((project: any) => project.id);
            
            const orderedProjects = projectsCopy.filter((project: any) => !selectedProjectIds.includes(project.id));
            orderProjects(orderedProjects);
            setSourceProjects(orderedProjects);
        }
    },[roleIsLoading, role, projectsIsLoading, projects]);

    const onUpdateRole = (e: any) => {
        e.preventDefault();
        onSave({name, resources: checkedResources, isBuiltIn: builtInRole, projects: selectedProjects });
      };

    const onChangeResourcesList = (e:any) => {            
        const value = e.target.value;
        if (checkedResources.includes(value)) {
            const newClaims = [...checkedResources].filter((id) => id !== value);
            setCheckedResources(newClaims);
        } else {
            const newClaims = [...checkedResources];
            newClaims.push(value);
            setCheckedResources(newClaims);
        }
    }

    const onAddResourcesList = (resourceIds:any[]) => {            
        const newClaims = Array.from(checkedResources);
        resourceIds.forEach((resourceId: any) => {
            if (!checkedResources.includes(resourceId)) {    
                newClaims.push(resourceId);
            }
        });
        
        setCheckedResources(newClaims);
    }

    const onRemoveResourcesList = (resourceIds:any[]) => {            
        const newClaims = Array.from(checkedResources);
        resourceIds.forEach((resourceId: any) => {
            var resoureceIndex = newClaims.indexOf(resourceId);
            if (resoureceIndex > -1) {    
                newClaims.splice(resoureceIndex, 1);
            }
        });
        
        setCheckedResources(newClaims);
    }

    const onAddProject = (project: any) => {
        const projectCopy = {...project, view: false, update: false };                
        const copy = [...selectedProjects, projectCopy];                
        orderProjects(copy);        
        setSelectedProjects(copy);

        const filteredProjects = sourceProjects.filter((p: any) => p.id !== project.id);
        setSourceProjects(filteredProjects);
    };

    const onRemoveProject = (project: any) => {
        const copy = [...sourceProjects, project];        
        orderProjects(copy);        
        setSourceProjects(copy);

        const filteredProjects = selectedProjects.filter((p: any) => p.id !== project.id);
        setSelectedProjects(filteredProjects);
    };

    const setPermission = (project: any, resource: any, permission: boolean) => {
        const selectedProjectsCopy = Array.from(selectedProjects);
        const selectedProjectIndex = selectedProjectsCopy.findIndex((p) => p.id === project.id);
        var selectedProject = selectedProjectsCopy.splice(selectedProjectIndex, 1)[0];
        selectedProject = {...selectedProject, [resource]: permission};
        
        const orderedProjects = [...selectedProjectsCopy, selectedProject];
        orderProjects(orderedProjects);
        setSelectedProjects(orderedProjects);
    };

    const orderProjects = (projects: any[]) => {        
        projects.sort((a: any, b: any) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
    };
        
    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {(isLoading || roleIsLoading || resourcesIsLoading) && <Loader/>}

                <Form name={name}                      
                    setName={setName}                       
                    builtInRole={builtInRole}
                    setBuiltInRole={setBuiltInRole}
                    onSaveRole={onUpdateRole}
                    controllers={resources?.controllers}
                    checkedResources={checkedResources}
                    onChangeResourcesList={onChangeResourcesList}
                    onAddResourcesList={onAddResourcesList}
                    onRemoveResourcesList={onRemoveResourcesList}                    
                    readOnly={readOnly}
                    projects={sourceProjects}
                    selectedProjects={selectedProjects} 
                    setSelectedProjects={setSelectedProjects}
                    onAddProject={onAddProject}
                    onRemoveProject={onRemoveProject}
                    setPermission={setPermission}
                    showSpecificProjects={showSpecificProjects}
                    />
                
            </div>
        </section>
    )    
};

export default RoleContainer;