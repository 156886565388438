import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { scaleLinear, scaleLog } from 'd3-scale';
import { curveMonotoneY, curveLinear } from 'd3-shape';
import { extent } from 'd3-array';
import { select, stack, scaleOrdinal, area, axisTop, axisLeft, pointer, bisector, line, path, symbol, symbolTriangle, symbolDiamond, symbolSquare } from 'd3';
import FormatNumber from '../format-number';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import lodashDebounce from 'lodash.debounce';
import ReactModal from 'react-modal';
import { ResizableBox, ResizeCallbackData  } from 'react-resizable';
import '../../../node_modules/react-resizable/css/styles.css';
import CurveDialog from './curve-dialog';
import XScaleDialog from './xscale-dialog';
import { v4 as uuidv4 } from 'uuid';
import PointStyles from './point-styles';
import {
  useWindowWidth
} from '@react-hook/window-size'

import './line-chart.scss';
import TrackWrapper from './track-wrapper';
import _ from 'lodash';

export default function CumulativeChart(props: any) {
  const svgRef: any = useRef();
  const rootSvgRef: any = useRef();  
  const widowWidthRef: any = useRef();

  const { 
      track, 
      id, 
      curves,      
      trackWidth,
      topMargin,
      index,
      startDepth, 
      endDepth, 
      depthUnit,
      showYAxis,       
      //updateCurves,  
      updateCurve,          
      showGridlines,
      showValueAxisAnnotation,
      scaleType,      
      setTrackWidth,
      editMode,
      availableHeight,
      headerHeight,
      minHeaderHeight,
      headerBottomPadding,
      depthMajorIntervals,
      showDepthMinorIntervals,
      depthMinorIntervals,
      showDepthGridLines,
      trackHeaderClick,
      parentRef,
      setMetaDataDepth
    } = props;

  const { datas, label, scale, isAverage, units, trackTypeId, isSelected, scrollIntoView } = track;

  const windowWidth = useWindowWidth();

  useEffect(() => {
    widowWidthRef.current = windowWidth;
  },[windowWidth]);

  const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
    setTrackWidth(id, data.size.width);
  };

  const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);

  const margins = {
    top: topMargin,
    right: 20,
    bottom: 0,
    left: showYAxis ? 40 : 10,
  };

  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  const width = trackWidth - margins.left - margins.right;
  const trackHeight = availableHeight - headerBottomPadding;
  const svgHeight = trackHeight - minHeaderHeight - 1;
  const graphHeight = svgHeight - margins.top - margins.bottom;
  
  const getYRange = (data: any) => {
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    return extent(data, (d: any) => d.depth);
  };
  

  const defaultTicks = 6;
  const xTicks = curves[0]?.manualMajorIntervals ? curves[0]?.majorIntervals : defaultTicks;
  const yTicks = depthMajorIntervals;

  var isMajorTick = function (index: any) {    
    if (!showDepthMinorIntervals) {      
      return true;
    }

    return (index % (+depthMinorIntervals + 1)) === 0;
  }

  const addGridLines = (svg: any, xScale: any, yScale: any) => {
    var xAxisGridlines = axisTop(xScale)
    .tickFormat(f => "")
    .tickSize(-graphHeight)
    .ticks(xTicks);
 
    svg.select(".x-axis-grid")
    .call(xAxisGridlines);  

    let ticks = yTicks;
    if (showDepthMinorIntervals) {
      ticks += yTicks * (+depthMinorIntervals);
    }

    var yAxisGridlines = 
    axisLeft(yScale)
    .tickFormat(f => "")
    .tickSize(-width)
    .ticks(ticks);
 
    svg.select(".y-axis-grid")
    .selectAll("*")
    .remove();

    if (showDepthGridLines) {
      const yAxisGrid = svg.select(".y-axis-grid")
      .call(yAxisGridlines);

      yAxisGrid.selectAll("g")
        .filter(function (d : any, i : any) {
          return !isMajorTick(i);
        })
        .classed("grid-minor-tick", true);
    } 
  };

  const onSetCurveXRange = (curveId: any, minimum: number, maximum: number, tickCount: number) => {
    minimum = Math.round(minimum * 100) / 100;
    maximum = Math.round(maximum * 100) / 100;
      
    let curveIndex = curves.findIndex((curve: any) => curve.id === curveId);
    if (curveIndex < 0) {
      return;
    }
  
    let curve :any = curves[curveIndex];
    let newCurve =  { ...curve, xScaleMinimumExtent: minimum, xScaleMaximumExtent: maximum };
  
    if (!curve.manualScaleXMinimum || !curve.manualScaleXMaximum) {    
      if (!curve.manualScaleXMinimum) {
        newCurve.xScaleMinimum = minimum;
      }
  
      if (!curve.manualScaleXMaximum) {
        newCurve.xScaleMaximum = maximum;
      }    
    }
  
    if (!curves[curveIndex].manualMajorIntervals) {
      newCurve.majorIntervals = defaultTicks;
    } 
    
    updateCurve(id, newCurve);
  };

  
const xScaleRange = (cumulativeData: any, curve: any, ) => {
  

  return [curve.manualScaleXMinimum ? curve.xScaleMinimum : 0, curve.manualScaleXMaximum ? curve.xScaleMaximum : 100];
};

const renderChart = (cumulativeData: any, curves: any[]) => {
  
  const curveIds = curves.map(c => c.id);  
  const svg = select(svgRef.current!);
  const rootSvg = select(rootSvgRef.current!);

  let lastDepth = cumulativeData[0].depth;
  let gapsToAdd: any[] = [];
  cumulativeData.forEach(function(d: any){
    if (d.depth - lastDepth > 1) {      
      gapsToAdd.push({depth: lastDepth + 0.5, gap: true});
    }

    // Compute the total
    var tot = 0;
    for (var i in curveIds) { 
      var name = curveIds[i]; 
      if (d[name]) {
        tot += +d[name];
      }
    }

    // Now normalize    
    for (var i in curveIds) { 
      var name = curveIds[i]; 
      if (d[name]) {        
        d[name] = tot === 0 ? 0 : d[name] / tot * 100;
      } else {        
        d[name] = 0;
      }
    }

    lastDepth = d.depth;
  });

  cumulativeData = [...cumulativeData, ...gapsToAdd];
  cumulativeData.sort((a: any, b: any) => a.depth - b.depth);

  console.log("cumulativeData after", cumulativeData);
  
  var stackGen = stack().keys(curveIds);
  var stackedSeries = stackGen(cumulativeData); 
  console.log("stackedSeries", stackedSeries);

  const yScale = scaleLinear()
  .domain(getYRange(cumulativeData))
  .range([0, graphHeight]);

 const xScale = scaleType == 0 ? scaleLinear()
  .domain(xScaleRange(cumulativeData, curves[0]))
  .range([0, width])
  .nice()
  :
  scaleLog()
  .domain(xScaleRange(cumulativeData, curves[0]))
  .range([0, width])
  .nice();

  curves.forEach((curve: any) => {
    onSetCurveXRange(curve.id, xScale.domain()[0], xScale.domain()[1], xScale.ticks(xTicks).length);
  });  

  const xScaleMax = (value: number) => {
    const scaleValue = xScale(value);
    if (scaleValue > width) {
      return width;
    }
    else if (scaleValue < 0) {
      return 0;
    } else {
      return scaleValue;
    }
  };
  
  var colorScale: any = scaleOrdinal()
  .domain(curveIds)
  .range(curves.map((curve: any) => curve.fillColor));

  let curveType = curveMonotoneY;
  
  switch (curves[0]?.curveType)
  {
    case 2:
      curveType = curveLinear;
      break; 
  }

 // area
  if (true) {
    var areaGen: any = area()
      .defined((d: any, i: number) => { return !d.data.gap; })
      .curve(curveType)
      .y((d: any) => yScale(d.data.depth))
      .x0((d) => xScale(d[0]))
      .x1((d) => xScale(d[1]));

      svg.selectAll(".areas")
      .data(stackedSeries)
      .join("path")
      .attr("d", areaGen)
      .attr("class", "areas")    
      .attr("fill", (d: any) => colorScale(d.key))
      .attr("stroke-linecap", "round")
      .attr('clip-path', `url(#clip-${id})`);
      
    // add in lines for when just single point between gaps

    var sel = rootSvg
    .select('g')
    .selectAll('g.series')
    .data(stackedSeries)
    .join('g')
    .classed('series', true)
    .style('fill', (d) => colorScale(d.key));
  
    sel.selectAll('rect')
    .data(function(d, i, allData) {return d.filter(function(d, i, allData){ 
  
        if (d.data.gap) {
          return false;
        }
  
        if (i === 0 && i < (allData.length - 1) && allData[i + 1].data.gap) {
          return true;
        }
  
        if (i > 0 && i < (allData.length - 1)) {
          if (allData[i - 1].data.gap && allData[i + 1].data.gap) {
            return true;
          }
        }
  
        return false ; })
      })
    .join('rect')
    .attr('height', 1)
    .attr('x', (d) => xScale(d[0]))
    .attr('y', (d) => yScale(d.data.depth))
    .attr('width', (d) => { console.log("d[1]", d[1]); return xScale(d[1]) -xScale(d[0])});
  }


 // bar charts
  if (false) {
      var sel = rootSvg
    .select('g')
    .selectAll('g.series')
    .data(stackedSeries)
    .join('g')
    .classed('series', true)
    .style('fill', (d) => colorScale(d.key));

    sel.selectAll('rect')
    .data((d) => d)
    .join('rect')
    .attr('height', 10)
    .attr('x', (d) => xScale(d[0]))
    .attr('y', (d) => yScale(d.data.depth) - 5)
    .attr('width', (d) => xScale(d[1]) -xScale(d[0]));
  }

    const xAxis: any = axisTop(xScale).tickFormat(f => FormatNumber(f)).ticks(xTicks);

    if (showValueAxisAnnotation) {
      svg.select(".x-axis")
      .call(xAxis);
     
     svg.select(".x-axis").selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "start");
    } else {
      svg.select(".x-axis")
      .selectAll("*").remove();;
    }

    addGridLines(svg, xScale, yScale);
    
 var bisect = bisector(function(d: any) { return d.depth; }).left;

 function handleMouseMove(event: any) {     
    const currentYPosition = pointer(event)[1];
    let currentXPosition = pointer(event)[0];
        
    const yValue = yScale.invert(currentYPosition);
    
    const index = bisect(cumulativeData, yValue);
    
    const value = cumulativeData[index]?.[curves[0].id];
    const depth = cumulativeData[index]?.depth;
    
    if (value == null || !depth) {
      return;
    }
    
    // Get the index of the xValue relative to the dataSet
    const focus = svg
    .select('.focus-circle');
        
    focus
    //.attr("cx", xScale(value))
    .attr("y1", yScale(depth))
    .attr("y2", yScale(depth))
    .raise(); 

    const rect = parentRef.current.getBoundingClientRect();
    if (rect.width - event.clientX < 170) {
      currentXPosition -= 150;
    }

    let valueArray: any[] = [];
    curves.forEach((curve: any) => {
      const val = cumulativeData[index]?.[curve.id];
      if (val) {
        valueArray.push({ displayName: curve.displayName, value: (Math.round(val * 10) / 10), units: "%", isAverage: curve.isAverage, scale: curve.scale});
      }
    });

    setMetaDataDepth({ depth, values: valueArray});
  }

    function handleMouseOver(event: any) {
      const focus = svg
      .select('.focus-circle');
      focus.style("opacity", 0.5);
      handleMouseMove(event);
    }

    function handleMouseOut() {
      const focus = svg
      .select('.focus-circle');    
      focus.style("opacity", 0);
      setMetaDataDepth(null);
    }

    svg.append("rect")
    .attr("class", "overlay" + uuidv4())
    .attr("width", width)
    .attr("height", graphHeight)
    .style("opacity", 0)
    .on("mouseover", handleMouseOver)
    .on("mouseout", handleMouseOut)
    .on("mousemove", handleMouseMove);
}

  useEffect(() => {
    if (!datas || datas.length === 0 || !curves) {
      return;
    }
    const reversedCurves = [...curves];    
    reversedCurves.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
    let count = 1;
    
    let cumulativeData: any[] = [];
    
    reversedCurves.forEach((curve: any, index: number) => {
      var trackTrackData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);
      trackTrackData?.data?.forEach((data: any, dataIndex: number) => {
        let foundIndex = cumulativeData.findIndex((c: any) => c.depth == data.depth);
        let foundData = foundIndex > -1 ? cumulativeData[foundIndex] : null;
        if (!data.gap) {
          if (!foundData) {
            cumulativeData.push({ depth: data.depth, [curve.id]: data.value, gap: data.gap });
          } else {            
            const gap = cumulativeData[foundIndex].gap && data.gap;
            cumulativeData[foundIndex] = {...foundData, [curve.id]: data.value, gap: gap};
          }
        }
      });
    });


    if (cumulativeData) {
      cumulativeData.sort((a: any, b: any) => a.depth - b.depth);
      renderChart(cumulativeData, reversedCurves);  
      count++;
    }
    
  }, [JSON.stringify(datas), 
      JSON.stringify(curves),
      availableHeight, 
      startDepth, 
      endDepth,       
      scaleType,
      trackWidth,
      editMode,
      showDepthMinorIntervals,
      depthMajorIntervals,
      showDepthGridLines,
      minHeaderHeight,
      depthMinorIntervals,
      showValueAxisAnnotation]);

  ReactModal.setAppElement('#root');

  if (!datas) {
    return null;
  }

const headerPadding = () => {

    const totalHeaderHeight = headerHeight * curves.length;
    const padding = minHeaderHeight - totalHeaderHeight;
    if (padding > 0) {
      return <div style={{minHeight: padding}}></div>
    }
    
    return null;
  };

const headerCurves = curves;

  return (
    <TrackWrapper trackWidth={trackWidth}           
      onResize={onResize}
      chartHeight={trackHeight}
      scrollIntoView={scrollIntoView}
      editMode={isSelected}
      trackClick={trackHeaderClick}>

    <div className="track-label" style={{marginLeft: "10px"}}>
        {headerPadding()}
        {(headerCurves || []).map((curve: any, index: number) => (
          <div key={curve.id} className={`${isSelected ? "highlighted-track" : ""}`}>
            <div className="header-color-box" style={{backgroundColor: curve.fillColor}}></div>
            {curve.noData && <span className="material-icons icon-inline track-header-icon" title="No Data">
                          do_not_disturb
                        </span>} {curve.displayName}
          </div>
        ))}        
      </div>
       
      <svg width={trackWidth}
        height={svgHeight}
        ref={rootSvgRef}>
         <defs>
          <clipPath id={`clip-${id}`}>
            <rect className="clip-rect" x={0} y={0} width={trackWidth - 30} height={graphHeight} />
          </clipPath>
        </defs>
        <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
          <g className="unit">
              <text className="unit-text"></text>
          </g>
          <g className="x-axis-grid gridLines vertical-grid-lines" strokeOpacity={showGridlines ? 1 : 0 }/>
          <g className="y-axis-grid gridLines horizontal-grid-lines" strokeOpacity={showDepthGridLines ? 1 : 0 }/>
          <g className="x-axis" />          
          <g className="y-axis" />          
          <line className="focus-circle" x1={0} y1={0} x2={width} y2={0} stroke="black" strokeWidth={1}></line>
        </g>
      </svg>
    </TrackWrapper>
  );  
}
