import React from 'react';
import parse from 'html-react-parser';
import FormatNumber from '../../../components/format-number';
import "./depth-data.scss";

const DepthData = (props: any) => {
    const {
      metaDataDepth,
      depthUnit
    } = props;    

    var isNumber = function isNumber(value: any) {
      return typeof value === 'number' && isFinite(value);
    }

return(
  <div className="depth-data">
       {metaDataDepth &&
                    <>
                    <span className='depth-data-span'>Depth: {Math.round(metaDataDepth?.depth * 1000) / 1000}  {depthUnit == 1? "m" : "ft"}</span>
                    {(metaDataDepth?.values || []).map((value: any, index: number) => (
                      <span key={index} className='depth-data-span'>
                        {value.displayName}: <span title={value.value}>{isNumber(value.value) ? FormatNumber(value.value) : value.value}</span>{parse(value.units)} {value.isAverage && <>Avg. {value.scale && <>{value.scale}x</>}</>}
                      </span>
                    ))}
                    </>
                  }
  </div>
)};

export default DepthData;