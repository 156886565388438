import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { boreholeApi } from '../services/boreholeApi';
import { uploadApi } from '../services/uploadApi';
import { enumApi } from '../services/enumApi';
import { lookupApi } from '../services/lookupApi';
import { templateApi } from '../services/templateApi';
import { projectApi } from '../services/projectApi';
import { accountApi } from '../services/accountApi';
import { trackTypeApi } from '../services/trackTypeApi';
import { imageApi } from '../services/imageApi';
import { userApi } from '../services/userApi';
import { roleApi } from '../services/roleApi';
import { clientApi } from '../services/clientApi';
import { pdfApi } from '../services/pdfApi';
import uploadFileReducer from '../redux/uploadFile/uploadFile.reducer';
import templateReducer from '../features/borehole/templateSlice';
import accountReducer from '../services/accountSlice';
import printReducer from "../services/printSlice";
import boreholeFilesReducer from "../services/borehole-files-slice";
import boreholeReducer from "../services/boreholesSlice";
import jobReducer from "../services/jobSlice";
import trackTypesReducer from "../services/trackTypeSlice";
import imageTypesReducer from "../services/imageTypeSlice";
import lithologyReducer from "../services/lithologySlice";

export const store: any = configureStore({
  reducer: {
    counter: counterReducer,
    uploadFile: uploadFileReducer,    
    [boreholeApi.reducerPath]: boreholeApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,    
    [enumApi.reducerPath]: enumApi.reducer,
    [lookupApi.reducerPath]: lookupApi.reducer,    
    [templateApi.reducerPath]: templateApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [trackTypeApi.reducerPath]: trackTypeApi.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [pdfApi.reducerPath]: pdfApi.reducer,
    templates: templateReducer,
    account : accountReducer,
    print: printReducer,
    boreholeFiles: boreholeFilesReducer,
    job: jobReducer,
    borehole: boreholeReducer,
    trackType: trackTypesReducer,
    imageType: imageTypesReducer,
    lithology: lithologyReducer
  },
  middleware: (getDefaultMiddleware) => 
  getDefaultMiddleware()
            .concat(boreholeApi.middleware)
            .concat(uploadApi.middleware)
            .concat(enumApi.middleware)
            .concat(lookupApi.middleware)
            .concat(templateApi.middleware)
            .concat(projectApi.middleware)
            .concat(accountApi.middleware)
            .concat(trackTypeApi.middleware)
            .concat(imageApi.middleware)
            .concat(userApi.middleware)
            .concat(roleApi.middleware)
            .concat(clientApi.middleware)
            .concat(pdfApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
