import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import './panel.scss';
import './track-order-panel.scss';
import TrackOrder from './track-order';
import RightPanel from './right-panel';

const TrackOrderPanel = (props: any) => {
    const {
      tracks,
      updateTrackOrder
    } = props;    

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    updateTrackOrder(source.index, destination.index);
  }

return (
  <RightPanel title="Reorder" icon="Atlas Reorder Icon">
    <DragDropContext onDragEnd={onDragEnd}>
       <Droppable droppableId={"trackorder"}>
         {(provided: any) => (
          <div ref={provided.innerRef}
          {...provided.droppableProps}>
              {(tracks || []).map((track: any, index: number) => (
                <TrackOrder key={track.id} track={track} index={index} />
              ))}
              {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
   </RightPanel>
)};

export default TrackOrderPanel;