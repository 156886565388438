import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { 
         useScaleImagesMutation, 
         useCreateDeepZoomImagesMutation
          } from "../../services/boreholeApi";
import Errors from '../../components/errors';
import Progress from '../../components/progress';
import getConfig from "../../app/config";
import { useAppDispatch } from '../../app/hooks';
import formatBytes from '../../components/FormatBytes';
import './BoreholeUpload.scss';
import auth from '../../components/auth';
import { RootState } from '../../app/store';
import { confirmAlert } from 'react-confirm-alert';
import {
        updateDataFile, 
        addImageFile,         
        deleteImageFile, 
        deleteImageFiles, 
        deleteSelectedImageFiles,
        updateImageFileProgress,
        updateImageFileSubProgress,
        removeScaleImageProgress,
        cancelScaleImageProgress,
        updateImageFileScaled,
        scaleImage,
        deepZoomImage,
        updateImageFileDeepZoomProgress,
        updateImageFileDeepZoomSubProgress,
        removeDeepZoomImageProgress,
        cancelDeepZoomImageProgress,
        updateImageFileDeepZoomCompleted
        } from "../../services/borehole-files-slice";
import Loader from '../../components/loader';
import TextField from '../../components/Input/text-field';
import BoreholeImageTypeFiles from "./BoreholeImageTypeFiles";
import './borehole-image-files.scss';

const BoreholeImageFiles = ({setBorehole}: any) => {
  const { boreholeId, clientId, projectId } = useParams<any>();
  const dispatch = useAppDispatch();

  const selectAccount = (state: RootState) => state.account;
  const { 
    connection,
    connected
  } = useSelector(selectAccount);

  useEffect(() => {

    const subscribeToProgress = async () => {
      if (!connection) {
        return;
      }

      connection.off("SendScaleImagesProgress");
      connection.off("SendScaleImagesSubProgress");
      connection.off("SendScaleImagesProgressJobDeleted");
      connection.off("SendCreateDeepZoomImagesProgress");
      connection.off("SendCreateDeepZoomImagesSubProgress");
      connection.off("SendCreateDeepZoomImagesSubProgress");
      connection.off("SendCreateDeepZoomImagesProgressJobDeleted");
      connection.off("SendImageFileExtracted");      
      connection.off("SendCreateDeepZoomImagesProgressJobCancelled");
      connection.off("SendImageScaled");
      connection.off("SendCreateDeepZoomImagesCompleted");
      connection.off("SendScaleImagesProgressJobCancelled");

      connection.on("SendScaleImagesProgress", (percent: any, message: any, imageType: any, failed: any, warning: any) => {
        dispatch(updateImageFileProgress({imageType, percent, message, failed, warning}));
      });   
      
      connection.on("SendScaleImagesSubProgress", (percent: any, imageType: any) => {
        dispatch(updateImageFileSubProgress({imageType, percent}));
      });   

      connection.on("SendScaleImagesProgressJobDeleted", (imageType: any) => {
        dispatch(removeScaleImageProgress({imageType}));
      });   

      connection.on("SendScaleImagesProgressJobCancelled", (imageType: any) => {        
        dispatch(cancelScaleImageProgress({imageType}));
      });   

      connection.on("SendImageScaled", (imageType: any) => {
        dispatch(updateImageFileScaled({imageType}));
      });   
      
      connection.on("SendCreateDeepZoomImagesProgressJobDeleted", (imageType: any) => {
        dispatch(removeDeepZoomImageProgress({imageType}));
      });   

      connection.on("SendCreateDeepZoomImagesProgressJobCancelled", (imageType: any) => {
        dispatch(cancelDeepZoomImageProgress({imageType}));
      });   

      connection.on("SendCreateDeepZoomImagesProgress", (progress: any, message: any, imageType: any, failed: any) => {
        dispatch(updateImageFileDeepZoomProgress({ progress, message, imageType, failed }));
      });     

      connection.on("SendCreateDeepZoomImagesSubProgress", (progress: any, imageType: any) => {
        dispatch(updateImageFileDeepZoomSubProgress({ progress, imageType }));
      });     

      connection.on("SendCreateDeepZoomImagesCompleted", (imageType: any) => {
        dispatch(updateImageFileDeepZoomCompleted({ imageType }));
      });     

      connection.on("SendDataFileExtracted", (dataFile: any) => {
        dispatch(updateDataFile(dataFile));      
      });  

      connection.on("SendImageFileExtracted", (imageFile: any) => {
        dispatch(addImageFile(imageFile));      
      });  

      connection.invoke("SubscribeToBorehole", boreholeId);
    }

    if (connected) {      
      if (connection?.state === "Connected") {
        subscribeToProgress();                
      }

      return function cleanUp() {
        if (connection?.state === "Connected") {
          connection?.invoke("UnsubscribeFromBorehole", boreholeId);
        }
      }
    }
    
  }, [connected]);
  const [showAllImageTypes, setShowAllImageTypes] = useState<boolean>(false);
  
  const [scaleImages, { isLoading: isScalingImages, error: scaleImagesError }] = useScaleImagesMutation();    
  const [createDeepZoomImages, { isLoading: isCreatingDeepZoomImages, error: deepZoomImagesError }] = useCreateDeepZoomImagesMutation();
  
  const selectBoreholeFiles = (state: RootState) => state.boreholeFiles;
  const {  
          imageFileUploads,
          deleteImageFileError,
          deleting,
          totalStorageSize
        } = useSelector(selectBoreholeFiles);
    
  const onScaleImages = (boreholeId: any) => {    
    scaleImages({boreholeId, projectId, clientId})    
  }

  const onShowAllImageTypes = () => {
    setShowAllImageTypes(!showAllImageTypes);
  };

  return (
  <>
        <h4>Image Files</h4>
        {deleting && <Loader/>}
        <Errors error={scaleImagesError || deleteImageFileError || deepZoomImagesError} />
        <div className="buttons mt-2">
            <button className='button' onClick={onShowAllImageTypes}>{showAllImageTypes ? "Hide non-uploaded" : "Show All"}</button>
            {(false) && <button className="button" onClick={() => onScaleImages(boreholeId)}>Create all scale images</button> }
            <span className='ml-2'>Total image storage size: {formatBytes(totalStorageSize)}</span>            
        </div>          
        <table className="table is-narrow upload-table">
        <thead>
          <tr>
            <th className="type-column">Type</th>
            <th className="has-text-centered">Uploaded</th>
            <th className="has-text-centered">Scaled</th>
            <th className="has-text-centered">Deep Zoom</th>
            <th>Progress</th>
            <th colSpan={2}>Files</th>
          </tr>
        </thead>
        <tbody>
          {(imageFileUploads?.filter((i:any) => i.isUploaded || showAllImageTypes) || []).map((imageFileUpload: any, index: number) => (
            <BoreholeImageTypeFiles imageFileUpload={imageFileUpload} index={imageFileUpload.imageType} />
          ))
          }        
      </tbody>
      </table>
    </>
  )
};

export default BoreholeImageFiles;