import React from 'react';
import PointStyles from './point-styles';
import { dashType } from './dash-type';
import parse from 'html-react-parser';
import FormatNumber from '../format-number';
import './track-header.scss';

const TrackHeader = (props: any) => {
    const { curve, trackWidth, curveXRange, isSelected, trackHeaderClick, last } = props;

    const lineLength = trackWidth - 20;

    const points = (curve: any) => {
        var rows = [];
        for(var i = 0; i < 10; i++) {
          rows.push(<g transform={`translate(${i * 60}, 0)`}><PointStyles style={curve.pointStyle} pointColor={curve.pointColor} /></g>);
        }  
        return rows;
      };
    
    return (
        <div className="track-header" onClick={trackHeaderClick} style={{marginBottom: last? 0: "13px" }}>
            <div title={curve.displayName} className={`track-header-label ${isSelected ? "highlighted-track" : ""}`}>
            {curve.noData && <span className="material-icons icon-inline track-header-icon" title="No Data">
                          do_not_disturb
                        </span>} {curve.displayName} {curve.units && <>[{parse(curve.units)}]</>} {curve.logLessThaZero && <span className="material-icons track-header-icon" title="Values less that zero are ignored">warning</span>}
            </div>
            <div className="columns track-header-columns">
                <div className="column has-text-left track-header-min-value" title={curveXRange[curve.id] ? curveXRange[curve.id].minimum : null}>
                {curveXRange[curve.id] ? FormatNumber(curveXRange[curve.id].minimum) : null}
                </div>                
                <div className="column has-text-right track-header-max-value" title={curveXRange[curve.id] ? curveXRange[curve.id].maximum : null}>
                {curveXRange[curve.id] ? FormatNumber(curveXRange[curve.id].maximum) : null}
                </div>
            </div>
            <div className="track-header-line">
                <svg width={lineLength} height={10}>
                    <g>
                    {curve.showLine && 
                        <path strokeDasharray={dashType(curve)} d={`M0 5 l${lineLength} 0`} stroke={curve.lineColor} strokeWidth={curve.lineSize} />
                    }
                    {curve.showPoints && 
                        <>
                        {points(curve)}
                        </>
                    }
                    </g>
                </svg>
            </div>            
        </div>
    )

}

export default TrackHeader;